// OOTB Code
'use strict';

const scrollAnimate = require('base/components/scrollAnimate');
const baseFormErrors = require('base/checkout/formErrors');


/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
baseFormErrors.loadFormErrors =  function(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
    // Animate to top of form that has errors
    scrollAnimate($(parentSelector).find('.is-invalid:first'));
};

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
baseFormErrors.clearPreviousErrors = function(parentSelector) {
    $(parentSelector).find('.form-control.is-invalid').removeClass('is-invalid');
    $('.error-message').hide();
};

module.exports = baseFormErrors;
