'use strict';

import { queryFirst, addClass, removeClass, hasClass, queryAll, scrollTo, setAttribute, throttle } from '../domUtil';
import base from './base';
import { updateProductData, handleColor, handleSize } from 'fitpredictor/product/secretsauce';

const { isShippingPreferencesViewEnabled, isEarlyAccessElement } = require('./helper');
const { availabilityMessageTmpl, availabilityMessageOOS, availabilityMessagePreorder, ispuAvailabilityMessageTmpl, ispuLowStockMessageTmpl, promotionMessageTmpl } = require('../templates').productDetail;
const $body = $('body');
const sizeChartClasses = 'no-scroll size-chart-visible';
var Hammer = require('hammerjs');
const KEYCODE_TAB = 9;
const TabKey = 'Tab';
const { IN_STOCK_STATUS: IN_STOCK, NOT_AVAILABLE_STATUS: NOT_AVAILABLE, PREORDER_STATUS: PREORDER, HIDDEN_CLASS } = require('../constants');
const { SHIP_TO_LOW_INVENTORY_CLASS } = require('../components/shippingPreference/constants');
const { handleEarlyAccessLogin } = require('../loyalty/loyaltyDrawer');
const { initPopoverPromotions, initPopoverClose, initPopoverCloseSelector, initPopoverWishList } = require('../popover');
import { getNestedValue } from '../util';
import { hideStrikeHearts, setWishlistProductId, showStrikeHearts } from '../wishlist/helper';

/**
 * Handling zoomin effect on product image slider
 */
function handleProductImageZoom() {
    const pdpPageCarousels = queryFirst('.product-detail');
    const carousel = $('.primary-images .carousel:not(.tile-carousel)');
    const imageZoomUrl = carousel.data('image');
    const imagePresetUrl = carousel.data('preset');

    const imageObserver = new MutationObserver(function (mutationList) {
        for (let mutation of mutationList) {
            if (mutation.type === 'childList') {
                const { target, addedNodes } = mutation;
                const baseImg = queryFirst('.img-fluid', target);
                const zoomAlt = baseImg.getAttribute('alt') + ' Zoomed';
                setAttribute(addedNodes, 'alt', zoomAlt);
            }
        }
    });

    if (pdpPageCarousels) {
        const pdpImages = queryAll('.primary-images .carousel-item');
        const pdpIndicators = queryAll('.primary-images .carousel-indicators li');

        pdpImages.forEach((img, index) => {
            const imageEl = queryFirst('img', img);
            if (!imageEl) {
                return;
            }
            imageEl.setAttribute('data-src-hires', imageEl.getAttribute('data-src').replace(imagePresetUrl, imageZoomUrl));
            let scale = 0.35;
            let zoomed = false;
            let mobileZoomed = false;
            /**
             * Handling zoomin effect logic
             * @param {jQuery} elm -  DOM element for current image
             * @param {boolean} zoomed - boolean value if zoomed or not
             */
            function createTouchZoom(elm) {
                var thisHammer = new Hammer(elm, {});
                thisHammer.domEvents = true;
                thisHammer.get('pinch').set({ enable: true });

                let transform = '';
                let el = elm;
                let panSpeed = 1.1;
                let xPos = { current: 0, last: 0, max: 0 };
                let yPos = { current: 0, last: 0, max: 0 };
                let scale = { current: 2, last: 2, max: 4 };

                el.style.transform = `translate(${xPos.current}px,${yPos.current}px) scale(${scale.current},${scale.current})`;

                thisHammer.on('tap pan pinch panend pinchend', function (e) {
                    // Prevent tap events from interfering
                    e.srcEvent.stopPropagation();
                    e.srcEvent.preventDefault();
                    // If zoomed in, pan the image
                    if (scale.current !== 1) {
                        xPos.current = xPos.last + e.deltaX * panSpeed;
                        yPos.current = yPos.last + e.deltaY * panSpeed;
                        xPos.max = Math.ceil(((scale.current - 1) * el.clientWidth) / 2);
                        yPos.max = Math.ceil(((scale.current - 1) * el.clientHeight) / 2);
                        // Keep coordinates within image bounds
                        if (xPos.current > xPos.max) {
                            xPos.current = xPos.max;
                        }
                        if (xPos.current < -xPos.max) {
                            xPos.current = -xPos.max;
                        }
                        if (yPos.current > yPos.max) {
                            yPos.current = yPos.max;
                        }
                        if (yPos.current < -yPos.max) {
                            yPos.current = -yPos.max;
                        }
                    }

                    if (e.type === 'tap') {
                        e.preventDefault();
                        scale.current++;
                        scale.last = scale.current;

                        if (scale.current > scale.max) {
                            scale.current = 1;
                            xPos.current = 0;
                            yPos.current = 0;
                            xPos.last = xPos.current;
                            yPos.last = yPos.current;
                            scale.last = scale.current;
                            transform = `translate(${xPos.current}px,${yPos.current}px) scale(${scale.current},${scale.current})`;
                            mobileZoomed = true;
                        } else mobileZoomed = false;
                    }
                    // Scale image with pinch
                    if (e.type === 'pinch') {
                        scale.current = Math.max(0.99, Math.min(scale.last * e.scale, scale.max));
                    }
                    // Finish scaling
                    if (e.type === 'pinchend') {
                        scale.last = scale.current;
                    }
                    // Finish panning
                    if (e.type === 'panend') {
                        xPos.last = xPos.current;
                        yPos.last = yPos.current;
                    }

                    // Create scale/pan changes if zoomed in
                    if (scale.current !== 1) {
                        transform = `translate(${xPos.current}px,${yPos.current}px) scale(${scale.current},${scale.current})`;
                    }
                    // Apply transformation
                    if (transform) {
                        el.style.transform = transform;
                    }
                });

                $(el).on('zoom:imageChange', () => {
                    thisHammer.off('tap pan pinch panend pinchend');
                    scale.current = 1;
                    xPos.current = 0;
                    yPos.current = 0;
                    xPos.last = xPos.current;
                    yPos.last = yPos.current;
                    scale.last = scale.current;
                    el.style.transform = `translate(${xPos.current}px,${yPos.current}px) scale(${scale.current},${scale.current})`;
                    el.style['touch-action'] = 'initial';
                    mobileZoomed = false;
                    zoomed = false;
                });
            }

            /** function to create zoom effect on hover
             * @param {jQuery} el - DOM element for current product image
             * @param {number} scale - scale value for the zoom
             */
            function createHoverZoom(el, scale) {
                let hiresUrl = $(el).find('img').attr('data-src-hires');
                if (hiresUrl && hiresUrl !== 'null' && hiresUrl.indexOf('noimagelarge') === -1) {
                    $(el).zoom({
                        on: 'click',
                        url: hiresUrl,
                        touch: false,
                        magnify: scale
                    });
                }
            }

            const setZoomOffset = function () {
                if (!zoomed) {
                    scale *= 1.5;
                    if (scale === 1.18125) {
                        zoomed = true;
                    }
                    createHoverZoom(img, scale);
                } else {
                    $(img).trigger('zoom-destroy');
                    $(img).find('.zoomImg').remove();
                    zoomed = false;
                    scale = 0.35;
                    createHoverZoom(img, scale);
                }
            };

            const allMobileZoom = function (event) {
                const img = event.target;
                if (!zoomed) {
                    img.src = img.getAttribute('data-src-hires');
                    createTouchZoom(img);
                }
                zoomed = true;
                if (!mobileZoomed) {
                    addClass(img.parentNode, 'touch-zoom');
                    $('.primary-images .carousel').carousel('dispose');
                    img.style['touch-action'] = 'none';
                }

                if (mobileZoomed) {
                    removeClass(img.parentNode, 'touch-zoom');
                    $('.primary-images .carousel.image-slider').carousel();
                    $('.primary-images .carousel').on('slide.bs.carousel', () => {
                        $('.primary-images .carousel-item img').trigger('zoom:imageChange');
                    });
                    img.style['touch-action'] = 'initial';
                }
            };

            if (window.matchMedia('(max-width: 1024px)').matches) {
                if (window.matchMedia('(max-width: 768px)').matches) {
                    if (pdpIndicators) {
                        $(pdpIndicators).on('click', () => {
                            const touchZoom = queryFirst('.primary-images .carousel-item.touch-zoom');
                            zoomed = false;
                            mobileZoomed = false;
                            if (touchZoom) {
                                $('.primary-images .carousel-item.touch-zoom img').trigger('zoom:imageChange');
                                removeClass(queryFirst('.primary-images .carousel-item.touch-zoom'), 'touch-zoom');
                            }
                            $('.primary-images .carousel.image-slider').carousel();
                            $('.primary-images .carousel').on('slide.bs.carousel', () => {
                                $('.primary-images .carousel-item img').trigger('zoom:imageChange');
                            });
                        });
                        if (index === 0) {
                            $('.primary-images .carousel').on('slide.bs.carousel', () => {
                                $('.primary-images .carousel-item img').trigger('zoom:imageChange');
                            });
                        }
                    }
                }
                img.addEventListener('click', allMobileZoom);
            } else {
                $(img).one('mouseenter', function () {
                    createHoverZoom(img, scale);
                });
                img.addEventListener('click', () => {
                    $(pdpImages).not($(img)).each(function () {
                        if ($(this).find('.zoomImg').length > 1) {
                            $(this).find('.zoomImg').remove();
                            scale = 0.35;
                            createHoverZoom($(this), scale);
                        }
                    });
                    setZoomOffset();
                });

                imageObserver.observe(img, { childList: true });
            }
        });
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Array} variationAttributes - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateSelectedSwatchProductName(variationAttributes, $productContainer) {
    if (Array.isArray(variationAttributes) && variationAttributes.length) {
        const colorVariationObject = variationAttributes.find(attribute => attribute.attributeId === 'color');

        if (colorVariationObject && Array.isArray(colorVariationObject.values) && colorVariationObject.values.length) {
            const selectedSwatchObject = colorVariationObject.values.find(eachValue => eachValue.selected);

            $productContainer.find('.selected-swatch-name').text(selectedSwatchObject ? selectedSwatchObject.displayValue : '');
        }
    }
}

/**
 * This method manages Notify me dialog content.
 *
 * !! For inline NotifyMe box please see handleNotifyMeBlock method
 *
 * @param {Object} productData - Product data
 * @param {Object} productContainer - Product Container DOM element
 */
function handleNotifyMe(productData, productContainer) {
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const notifyContainer = queryFirst('.js-notify-me-container', productContainer);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const notifyMeDesc = queryFirst('.js-notify-me-desc', productContainer);
    const availabilityMessageEl = queryFirst('.product-availability', productContainer);
    const hiddenClass = 'd-none';
    const { available, isNotifyMeEnabled, id, formattedPrice, forceOutOfStock, imageData } = productData;
    if (!available && isNotifyMeEnabled && !forceOutOfStock) {
        addClass(addToCartSection, hiddenClass);
        if (availabilityMessageEl) {
            addClass(availabilityMessageEl, hiddenClass);
        }
        removeClass([notifyMeDesc, notifyMeButton], hiddenClass);
        const notifyImageElement = queryFirst('.js-notify-img img', notifyContainer);
        if (imageData && notifyImageElement) {
            const { alt, url, srcset } = imageData[0];
            const imageHasLoaded = hasClass(notifyImageElement, 'lz-loaded');

            notifyImageElement.setAttribute(imageHasLoaded ? 'src' : 'data-src', url);
            notifyImageElement.setAttribute(imageHasLoaded ? 'srcset' : 'data-srcset', srcset);
            notifyImageElement.setAttribute('alt', alt);
        }
        // Insert price
        queryFirst('.js-notify-price', notifyContainer).textContent = formattedPrice;
        // Insert color value
        const notifyColor = queryFirst('.js-notify-color', notifyContainer);
        queryFirst('.js-notify-color-label', notifyColor).textContent = queryFirst('.selected-swatch', productContainer).dataset.colorLabel;
        queryFirst('.js-notify-color-value', notifyColor).textContent = queryFirst('.ssColor', productContainer).value;
        // Insert size value
        const notifySize = queryFirst('.js-notify-size', notifyContainer);
        queryFirst('.js-notify-size-label', notifySize).textContent = queryFirst('.size-display-name', productContainer).value + ':';
        queryFirst('.js-notify-size-value', notifySize).textContent = queryFirst('.size-btn.selected', productContainer).dataset.attrValue;
        if (id) {
            document.getElementById('notifySku').value = id;
        }
        const customerEmail = (document.getElementById('notifyEmail') || {}).value;
        if (customerEmail) {
            queryFirst('.notify-email').value = customerEmail;
        }
        removeClass(queryFirst('.js-notify-me-desc'), hiddenClass);
        removeClass(queryFirst('.js-notify-form'), hiddenClass);
        addClass(queryFirst('.js-notify-confirm-block'), hiddenClass);

        $body.trigger('product:notifyMeShown', productContainer);
    } else {
        removeClass(addToCartSection, hiddenClass);
        if (availabilityMessageEl) {
            removeClass(availabilityMessageEl, hiddenClass);
        }
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);

        $body.trigger('product:notifyMeHidden', productContainer);
    }
}

/**
 * handles size change
 * @param {Object} productContainer - product container html element
 * @param {string} selectedSizeValue - Selected size value
 */
function onSizeChangeHandler(productContainer, selectedSizeValue) {
    removeClass(queryAll('.size-btn', productContainer), 'selected');
    addClass(this, 'selected');
    const sizeContainer = this.closest('.size-container');
    const assistiveElements = queryAll('.selected-assistive-text', sizeContainer);
    assistiveElements.forEach(eachElement => {
        if (eachElement.textContent.includes(eachElement.dataset.outOfStock)) {
            eachElement.textContent = eachElement.dataset.outOfStock;
        } else {
            eachElement.textContent = '';
        }
    });
    const assistiveElementOfSelected = queryFirst('.selected-assistive-text', this.closest('.size-list'));
    const { selectedText, outOfStock } = assistiveElementOfSelected.dataset;
    assistiveElementOfSelected.textContent = selectedText;
    if (hasClass(this, 'not-available')) {
        assistiveElementOfSelected.textContent += ' ' + outOfStock;
    }
    removeClass(queryFirst('.size-seperator', productContainer), 'd-none');
    const selectedSize = queryFirst('.selected-size', productContainer);
    if (selectedSize) {
        selectedSize.textContent = selectedSizeValue;
        removeClass(selectedSize, 'd-none');
    }
}

/**
 * update quickview product info on product variation change
 * @param {string} selectedSizeValue - Selected size value
 * @param {int} selectedColorId - selected color id
 * @param {Object} productContainer - product container html element
 * @param {Object} currentSizeElement - current active size element
 */
function updateQuickViewProductInfo(selectedSizeValue, selectedColorId, productContainer, currentSizeElement) {
    const quickViewInfo = window.quickviewProductInfo;
    const { productInfo } = quickViewInfo;
    const variantGroupData = productInfo.variants[selectedColorId];
    const { sizes, images, formattedPrice, standardPrice, price, vgProductDetailsUrl, isDirectlyPurchasable, wishlistDisabled, isFinalSale } = variantGroupData;
    const ispu = base.updateImageDetails(images.ispu);
    const selectedSizeData = sizes[selectedSizeValue];
    const addToCartButton = queryFirst('.add-to-cart', productContainer);
    const hiddenClass = 'd-none';
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainer);
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const availabilityMsgEl = queryFirst('.availability-msg', productContainer);
    let availabilityValue = '';
    let productDetailsUrl = vgProductDetailsUrl;
    const fullPDPLink = queryFirst('.full-pdp-link', productContainer);
    const wishlistButton = queryFirst('.add-to-wish-list', productContainer);

    if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
        removeClass(wishlistButton, 'added-to-wish-list');
        wishlistButton.disabled = false;
    }
    hideStrikeHearts(productContainer);
    if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
        showStrikeHearts(productContainer);
    }

    if (!selectedSizeData || !currentSizeElement) {
        removeClass(addToCartSection, hiddenClass);
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);
        fullPDPLink.href = productDetailsUrl;
        if (!isDirectlyPurchasable) {
            addToCartButton.disabled = true;
            availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
            availabilityMsgEl.innerHTML = availabilityValue;
        } else {
            addToCartButton.disabled = false;
        }
        return;
    }

    const { isNotifyMeEnabled, ID, forceOutOfStock, variantProductDetailsUrl, isFinalSale: isFinalSaleVariant, isDirectlyPurchasable: isDirectlyPurchasableVariant, wishlistDisabled: wishlistDisabledVariant } = selectedSizeData;
    productDetailsUrl = variantProductDetailsUrl;
    const { productInventory } = quickViewInfo;
    const { variants } = productInventory;
    const inventoryData = variants[ID];
    const { message, availabilityStatus, isLowInventory } = inventoryData;
    const selectedColorName = queryFirst('.selected-swatch-name').textContent;

    if (!isDirectlyPurchasable) {
        availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
    } else if (availabilityStatus !== IN_STOCK || isLowInventory) {
        availabilityValue = availabilityMessageTmpl(message);
    }
    fullPDPLink.href = productDetailsUrl;

    if (!isDirectlyPurchasable) {
        addToCartButton.disabled = true;
        removeClass(addToCartSection, hiddenClass);
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);
    } else if (availabilityStatus !== NOT_AVAILABLE && !forceOutOfStock) {
        addToCartButton.disabled = false;
        removeClass(currentSizeElement, 'not-available');
        hideStrikeHearts(productContainer);
    } else {
        addClass(currentSizeElement, 'not-available');
        addToCartButton.disabled = true;
        showStrikeHearts(productContainer);
        if (isNotifyMeEnabled) {
            addClass(addToCartSection, hiddenClass);
            removeClass([notifyMeDesc, notifyMeButton], hiddenClass);
        } else {
            removeClass(addToCartSection, hiddenClass);
            addClass([notifyMeDesc, notifyMeButton], hiddenClass);
        }
    }

    availabilityMsgEl.innerHTML = availabilityValue;
    productContainer.dataset.pid = ID;
    productContainer.dataset.wishlistId = ID;
    setWishlistProductId(ID, productContainer);
    const productData = {
        available: !hasClass(currentSizeElement, 'not-available'),
        isNotifyMeEnabled,
        id: ID,
        formattedPrice,
        forceOutOfStock,
        imageData: ispu
    };
    if (wishlistDisabledVariant || !isDirectlyPurchasableVariant || isFinalSaleVariant) {
        showStrikeHearts(productContainer);
    }
    handleNotifyMe(productData, productContainer);

    // secret sauce integration to update product data
    updateProductData(selectedColorName, selectedSizeValue, standardPrice, price, productContainer);
    handleSize(productContainer);

    $('body').trigger('product:afterQuickViewSizeChange', {
        ID,
        productContainer,
        monetateData: {
            pdpBreadCrumbs: variantGroupData.pdpBreadCrumbs
        }
    });
}

/**
 * This method manages Notify me block on PDP
 * @param {Object} productData - Product data
 * @param {Object} productContainer - Product Container DOM element
 */
function handleNotifyMeBlock(productData, productContainer) {
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const notifyBlock = queryFirst('.js-notify-me-container', productContainer);
    const availabilityMessageEl = queryFirst('.product-availability', productContainer);
    const { available, isNotifyMeEnabled, id, forceOutOfStock } = productData;
    if (!available && isNotifyMeEnabled && !forceOutOfStock) {
        addClass([addToCartSection, availabilityMessageEl], HIDDEN_CLASS);
        removeClass(notifyBlock, HIDDEN_CLASS);

        if (id) queryFirst('#notifySku', notifyBlock).value = id;
        const custEmail = (queryFirst('#notifyEmail') || {}).value;
        if (custEmail) queryFirst('.notify-email').value = custEmail;
    } else {
        removeClass([addToCartSection, availabilityMessageEl], HIDDEN_CLASS);
        addClass(notifyBlock, HIDDEN_CLASS);
    }
}

/**
 *
 * @param {string} selectedSizeValue - Selected size value
 * @param {int} selectedColorId - selected color id
 * @param {Object} productContainer - product container html element
 * @param {Object} currentSizeElement - current active size element
 */
function updateProductInfo(selectedSizeValue, selectedColorId, productContainer, currentSizeElement) {
    const masterId = productContainer.dataset.masterid;
    const productInfo = window.productInfo[masterId];
    const variantGroupData = productInfo.variants[selectedColorId];
    const { sizes, images, formattedPrice, totalPrice, monogramProductPrice, standardPrice, price, mgFlag, mgLocs, isDirectlyPurchasable, wishlistDisabled, isFinalSale } = variantGroupData;
    const ispu = base.updateImageDetails(images.ispu);
    const selectedSizeData = sizes[selectedSizeValue];
    const addToCartButton = queryFirst('.add-to-cart', productContainer);
    const monogramBtn = queryFirst('.monogram-btn', productContainer);
    const ispuButton = queryFirst('.btn-in-store-pickup');
    const hiddenClass = 'd-none';
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainer);
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const availabilityMsgEl = queryFirst('.availability-msg', productContainer);
    const ispuLowStockMsgEl = queryFirst('.ispu-low-stock-msg', productContainer);
    let availabilityValue = '';

    hideStrikeHearts(productContainer);
    if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
        showStrikeHearts(productContainer);
    }

    if (!selectedSizeData || !currentSizeElement) {
        if (ispuButton) {
            ispuButton.disabled = true;
        }
        if (monogramBtn) {
            monogramBtn.disabled = true;
        }
        removeClass(addToCartSection, hiddenClass);
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);
        if (!isDirectlyPurchasable) {
            addToCartButton.disabled = true;
            availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
            availabilityMsgEl.innerHTML = availabilityValue;
            if (ispuButton) {
                ispuButton.disabled = true;
            }
        } else {
            addToCartButton.disabled = false;
        }
        return;
    }

    const { isNotifyMeEnabled, ID, forceOutOfStock, promotions, isFinalSale: isFinalSaleVariant, isDirectlyPurchasable: isDirectlyPurchasableVariant, wishlistDisabled: wishlistDisabledVariant } = selectedSizeData;
    const { variants } = window.productInventory;
    const inventoryData = variants[ID];
    const { message, availabilityStatus, isLowInventory } = inventoryData;

    // Shipping preference view
    const shippingPreferencesEnabled = isShippingPreferencesViewEnabled();
    let ispuAvailabilityValue = '';
    let ispuLowStockValue = '';
    let ispuVariants;
    let ispuInventoryData;
    let ispuMessage;
    let ispuLowStockMessage;
    let ispuAvailabilityStatus;
    let isISPULowInventory;
    if (shippingPreferencesEnabled && window.ispuProductInventory) {
        ispuVariants = window.ispuProductInventory.variants;
        ispuInventoryData = ispuVariants[ID];

        if (ispuInventoryData) {
            ispuMessage = ispuInventoryData.message;
            ispuLowStockMessage = ispuInventoryData.lowStockAvailabilityMessage;
            ispuAvailabilityStatus = ispuInventoryData.availabilityStatus;
            isISPULowInventory = ispuInventoryData.isLowInventory;
        }
    }

    const selectedColorName = queryFirst('.selected-swatch-name').textContent;
    if (!isDirectlyPurchasable) {
        availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
    } else {
        if (availabilityStatus !== IN_STOCK || isLowInventory) {
            const breadcrumbItems = queryAll('.breadcrumb-item');
            const categoryUrl = breadcrumbItems ? breadcrumbItems.pop().lastElementChild.href : null;
            if (availabilityStatus === NOT_AVAILABLE && categoryUrl) {
                availabilityValue = availabilityMessageOOS(message, categoryUrl);
            } else if (availabilityStatus === PREORDER && categoryUrl) {
                availabilityValue = availabilityMessagePreorder(message, categoryUrl);
            }
            else {
                availabilityValue = availabilityMessageTmpl(message);
            }
            if (isLowInventory) addClass(currentSizeElement, SHIP_TO_LOW_INVENTORY_CLASS);
        }

        // Shipping preference view
        if (ispuAvailabilityStatus && (ispuAvailabilityStatus !== IN_STOCK || isISPULowInventory)) {
            ispuAvailabilityValue = ispuAvailabilityMessageTmpl(ispuMessage);
            ispuLowStockValue = ispuLowStockMessage ? ispuLowStockMessageTmpl(ispuLowStockMessage) : '';
        }
    }

    if (!isDirectlyPurchasable) {
        addToCartButton.disabled = true;
        removeClass(addToCartSection, hiddenClass);
        addClass([notifyMeDesc, notifyMeButton], hiddenClass);
    } else if ((availabilityStatus !== NOT_AVAILABLE || (ispuAvailabilityStatus && ispuAvailabilityStatus !== NOT_AVAILABLE)) &&
        !forceOutOfStock) {
        addToCartButton.disabled = false;
        removeClass(currentSizeElement, 'not-available');
        hideStrikeHearts(productContainer);
    } else {
        addClass(currentSizeElement, 'not-available');
        addToCartButton.disabled = true;
        showStrikeHearts(productContainer);
        if (isNotifyMeEnabled) {
            addClass(addToCartSection, hiddenClass);
            removeClass([notifyMeDesc, notifyMeButton], hiddenClass);
        } else {
            removeClass(addToCartSection, hiddenClass);
            addClass([notifyMeDesc, notifyMeButton], hiddenClass);
        }
    }
    if (availabilityStatus === PREORDER) {
        addClass(currentSizeElement, 'pre-order');
    }

    if (shippingPreferencesEnabled) {
        $body.trigger('product:inventoryStatus', {
            shipToAddressAvailabilityStatus: availabilityStatus,
            inStorePickUpAvailabilityStatus: ispuAvailabilityStatus || NOT_AVAILABLE,
            isStorePickUpLowInventory: isISPULowInventory,
            inStorePickUpAvailabilityMessage: ispuAvailabilityValue,
            inStorePickUpLowStockMessage: ispuLowStockValue,
            productContainer
        });
    }
    availabilityMsgEl.innerHTML = availabilityValue;
    productContainer.dataset.pid = ID;
    queryFirst('.product-id', productContainer).textContent = ID;
    setWishlistProductId(ID, productContainer);
    productContainer.dataset.wishlistId = ID;
    const productData = {
        available: !hasClass(currentSizeElement, 'not-available'),
        isNotifyMeEnabled,
        id: ID,
        formattedPrice,
        forceOutOfStock,
        imageData: ispu
    };
    if (wishlistDisabledVariant || !isDirectlyPurchasableVariant || isFinalSaleVariant) {
        showStrikeHearts(productContainer);
    }
    handleNotifyMeBlock(productData, productContainer);
    if (!isDirectlyPurchasable) {
        if (ispuButton) {
            ispuButton.disabled = true;
        }
    } else if (ispuButton) {
        ispuButton.disabled = false;
        ispuButton.dataset.pid = ID;
        const pickupImageElement = queryFirst('.pickup-product-img img', productContainer);
        if (pickupImageElement && ispu) {
            const { alt, url, srcset } = ispu[0];
            const imageHasLoaded = hasClass(pickupImageElement, 'lz-loaded');

            pickupImageElement.setAttribute(imageHasLoaded ? 'src' : 'data-src', url);
            pickupImageElement.setAttribute(imageHasLoaded ? 'srcset' : 'data-srcset', srcset);
            pickupImageElement.setAttribute('alt', alt);
        }
        queryFirst('.pickup-price').textContent = formattedPrice;
        queryFirst('.pickup-color .selected-color').textContent = selectedColorName;
        const pickupSize = queryFirst('.pickup-size');
        queryFirst('.size-label', pickupSize).textContent = queryFirst('.size-display-name').value;
        const sizeSelected = queryFirst('.size-card .size-btn.selected');
        if (sizeSelected) {
            queryFirst('.selected-size', pickupSize).textContent = sizeSelected.dataset.attrValue;
        }
    }

    const product = {};
    if (mgFlag && mgLocs && mgLocs.length > 0) {
        const monogramImages = images.monogram || [];
        let url = '';
        let srcset = '';
        if (monogramImages.length) {
            const updatedImageData = base.updateImageDetails(monogramImages)[0];
            url = updatedImageData.url;
            srcset = updatedImageData.srcset;
        }
        const monogramObject = {
            monogramProductID: ID,
            monogramImageURL: url,
            monogramImageSrcSetURL: srcset,
            monogramLocations: mgLocs,
            monogramSelectedColor: selectedColorName,
            monogramSelectedSize: selectedSizeValue,
            productPrice: formattedPrice,
            monogramProductPrice,
            totalPrice: totalPrice
        };
        product.monogramObject = monogramObject;
    }

    // secret sauce integration to update product data
    updateProductData(selectedColorName, selectedSizeValue, standardPrice, price, productContainer);
    handleSize(productContainer);
    $('body').trigger('product:afterSizeChange', {
        data: {
            product
        },
        ID,
        productContainer
    });
    if (availabilityStatus !== NOT_AVAILABLE && !forceOutOfStock && !hasClass(monogramBtn, hiddenClass) && isDirectlyPurchasable) {
        monogramBtn.disabled = false;
    } else {
        monogramBtn.disabled = true;
    }

    // update promotion messages display for selected variation
    queryFirst('.promotions').innerHTML = promotions ? promotions.filter((promotion) => promotion.calloutMsg).map((promotion, promotionIdx) => {
        return promotionMessageTmpl(promotion.calloutMsg, promotion.details, promotionIdx);
    }).join('') : '';

    if (wishlistDisabled) {
        showStrikeHearts(productContainer);
    }

    // pick new promotion popover elements
    initPopoverPromotions();
    initPopoverCloseSelector();
    initPopoverWishList();
    initPopoverClose();
}

const init = () => {
    $('body').on('product:handleImageZoom', handleProductImageZoom);
    $('.carousel.image-slider').carousel();
};

const handleLastLinkTab = e => {
    const sizeChartCatBtn = queryFirst('#sizechart-menu-toggle');
    const isTabPressed = e.key === TabKey || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
        return;
    }

    if (!e.shiftKey) {
        sizeChartCatBtn.focus();
    }
};

/**
 * Toogle Size chart and select needed category
 * @param {Object} $this - Size chart link JQUERY element
 * @param {Object} $prodSizeChart) - Size chart window element
 */
function toogleActiveSizeChartTab($this, $prodSizeChart) {
    const $lpSlideout = $('.lp-sizechart');
    const { sizefamily: activeCategoryVal, defaulttext: defaultText } = $this.data();
    const $activeCategoryEl = $prodSizeChart.find(`a[href="#${activeCategoryVal}-size"]`);
    const $lpSizechartTitle = $prodSizeChart.find('.lp-sizechart-category-btn');
    $lpSizechartTitle.focus();
    $activeCategoryEl.tab('show');
    $lpSizechartTitle.text($activeCategoryEl.text().length ? $activeCategoryEl.text() : defaultText);

    $prodSizeChart.animate(
        {
            right: '0'
        },
        500
    );
    $prodSizeChart.addClass('active');
    $lpSlideout.toggleClass('lp-slideout-open');
    $body.toggleClass(sizeChartClasses);
}

export default {
    init: init,

    availability: base.availability,

    addToCart: base.addToCart,

    scrollFitRatingToReviews: function () {
        $(document).on('click', 'button.fit-review, button.no-fit-review', function (e) {
            e.preventDefault();
            const bvReviews = document.getElementById('bazaarvoiceReviews');
            if (bvReviews) {
                scrollTo(window.scrollY + bvReviews.getBoundingClientRect().top, 0);
            }
        });
    },

    handleProductImageZoom: handleProductImageZoom,

    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            const { container } = response;
            const { id, variationAttributes } = response.data.product;
            if ($('.product-detail>.bundle-items').length) {
                container.data('pid', id);
                container.find('.product-id').text(id);
            } else if ($('.product-set-detail').eq(0)) {
                container.data('pid', id);
                container.find('.product-id').text(id);
            } else {
                $('.product-id').text(id);
                $('.product-detail:not(".bundle-item")').data('pid', id);
            }
            updateSelectedSwatchProductName(variationAttributes, container);
            $('body').trigger('product:handleImageZoom');
        });
    },

    selectSizeAttribute: function () {
        $(document).on('click', '.set-items .size-btn, .product-quickview .size-btn', function (e) {
            e.preventDefault();
            const buttonElement = e.target;
            if (!hasClass(buttonElement, 'selected')) {
                const $productContainer = $(this).closest('.product-detail');
                const url = buttonElement.dataset.attrUrl;
                if ($productContainer.hasClass('cached-quick-view')) {
                    const productContainer = this.closest('.product-detail');
                    const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainer);
                    const selectedColorId = selectedColorElement.dataset.attrValue;
                    const selectedSizeValue = this.dataset.attrValue;
                    onSizeChangeHandler.apply(this, [productContainer, selectedSizeValue]);
                    updateQuickViewProductInfo(selectedSizeValue, selectedColorId, productContainer, this);
                } else {
                    base.attributeSelect(url, $productContainer, buttonElement);
                }
            }
        });
        $('body').on('product:quickViewAttributeChange', (e, response) => {
            const selectedSizeElement = queryFirst('.product-quickview.cached-quick-view .size-btn.selected');
            const selectedValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
            updateQuickViewProductInfo(selectedValue, response.variantGroupId, response.container[0], selectedSizeElement);
        });
        window.addEventListener('resize', throttle(() => {
            const quickViewCarouselEl = queryFirst('.product-quickview.cached-quick-view .carousel');
            if (quickViewCarouselEl) {
                quickViewCarouselEl.style.minHeight = '';
            }
        }, 16));
    },
    afterAttributeSelect: function () {
        $('body').on('product:afterAttributeSelect', (event, response) => {
            const { selectedSizeElement } = response;
            const { product } = response.data;
            const { id, readyToOrder, available, forceOutOfStock, price, wishListID, images, isDirectlyPurchasable, wishlistDisabled } = product;
            const { ispu } = images;
            const responseContainer = response.container[0];
            const addToCartButton = queryFirst('.add-to-cart', responseContainer);
            responseContainer.dataset.pid = id;
            responseContainer.dataset.wishlistId = wishListID;
            setWishlistProductId(wishListID, responseContainer);

            let ssSize = 'unkown';
            const hiddenClass = 'd-none';
            if (hasClass(responseContainer, 'product-quickview') && addToCartButton) {
                addToCartButton.disabled = false;
            }
            if (selectedSizeElement) {
                ssSize = selectedSizeElement.dataset.attrValue;
                removeClass(queryAll('.size-btn', responseContainer), 'selected');
                addClass(selectedSizeElement, 'selected');
                const sizeCard = selectedSizeElement.closest('.size-card');
                const selectedSize = queryFirst('.selected-size', sizeCard);
                if (selectedSize) {
                    const sizeSeparator = queryFirst('.size-seperator', sizeCard);
                    selectedSize.textContent = selectedSizeElement.dataset.attrValue;
                    removeClass([sizeSeparator, selectedSize], hiddenClass);
                }
                const sizeContainer = selectedSizeElement.closest('.size-container');
                const assistiveElements = queryAll('.selected-assistive-text', sizeContainer);
                assistiveElements.forEach(eachElement => {
                    if (eachElement.textContent.includes(eachElement.dataset.outOfStock)) {
                        eachElement.textContent = eachElement.dataset.outOfStock;
                    } else {
                        eachElement.textContent = '';
                    }
                });
                const assistiveElementOfSelected = queryFirst('.selected-assistive-text', selectedSizeElement.closest('.size-list'));
                const { selectedText, outOfStock } = assistiveElementOfSelected.dataset;
                assistiveElementOfSelected.textContent = selectedText;
                if (hasClass(selectedSizeElement, 'not-available')) {
                    assistiveElementOfSelected.textContent += ' ' + outOfStock;
                }
                if (addToCartButton) {
                    const restrictedFromOrder = !readyToOrder || !available || forceOutOfStock;
                    addToCartButton.disabled = restrictedFromOrder;
                    if (restrictedFromOrder) {
                        showStrikeHearts(responseContainer);
                    } else {
                        hideStrikeHearts(responseContainer);
                    }
                }

                const quickviewContainer = selectedSizeElement.closest('.product-quickview');
                if (quickviewContainer) {
                    const addToCartSection = queryFirst('.prices-add-to-cart-actions', responseContainer);
                    const notifyMeButton = queryFirst('.notify-me-btn', responseContainer);
                    const notifyMeDesc = queryFirst('.notify-me-desc', responseContainer);
                    const availabilityMessageEl = queryFirst('.product-availability', responseContainer);
                    const { isNotifyMeEnabled, isDirectlyPurchasable } = product;
                    const salesPrice = price.type === 'range' ? price.min.sales : price.sales;
                    const productData = {
                        available,
                        isNotifyMeEnabled,
                        id,
                        formattedPrice: salesPrice.formatted,
                        forceOutOfStock,
                        imageData: ispu
                    };
                    handleNotifyMe(productData, quickviewContainer);
                    const updateCartButton = queryFirst('.update-cart-product-global', quickviewContainer);
                    if (updateCartButton) {
                        const { giftWrapAvailableFlag } = product;
                        updateCartButton.dataset.giftWrapAvailable = giftWrapAvailableFlag;
                    }
                    if (isDirectlyPurchasable === false) {
                        removeClass(addToCartSection, hiddenClass);
                        if (availabilityMessageEl) {
                            removeClass(availabilityMessageEl, hiddenClass);
                        }
                        addClass([notifyMeDesc, notifyMeButton], hiddenClass);
                    }
                }

                const ispuButton = queryFirst('.btn-in-store-pickup', responseContainer);
                if (ispuButton) {
                    ispuButton.dataset.pid = id;
                    ispuButton.disabled = false;
                    const pickupImageElement = queryFirst('.pickup-product-img img', responseContainer);
                    if (pickupImageElement && ispu) {
                        const { alt, url, srcset } = ispu[0];
                        pickupImageElement.setAttribute('src', url);
                        pickupImageElement.setAttribute('srcset', srcset);
                        pickupImageElement.setAttribute('alt', alt);
                    }
                    queryFirst('.pickup-product-name', responseContainer).textContent = product.productName;
                    queryFirst('.pickup-price').textContent = product.price.sales.formatted;
                    const selectedSwatchName = queryFirst('.selected-swatch-name', responseContainer);
                    if (selectedSwatchName) {
                        queryFirst('.pickup-color .selected-color', responseContainer).textContent = selectedSwatchName.textContent;
                    }
                    const pickupSize = queryFirst('.pickup-size', responseContainer);
                    queryFirst('.size-label', pickupSize).textContent = queryFirst('.size-display-name', responseContainer).value;
                    const sizeSelected = queryFirst('.size-card .size-btn.selected', responseContainer);
                    if (sizeSelected) {
                        queryFirst('.selected-size', pickupSize).textContent = sizeSelected.dataset.attrValue;
                    }
                }
            }
            if (hasClass(responseContainer, 'product-quickview') && isDirectlyPurchasable === false) {
                const addToCartSection = queryFirst('.prices-add-to-cart-actions', responseContainer);
                const notifyMeButton = queryFirst('.notify-me-btn', responseContainer);
                const notifyMeDesc = queryFirst('.notify-me-desc', responseContainer);
                const availabilityMessageEl = queryFirst('.product-availability', responseContainer);
                removeClass(addToCartSection, hiddenClass);
                if (addToCartButton) {
                    addToCartButton.disabled = true;
                }
                if (availabilityMessageEl) {
                    removeClass(availabilityMessageEl, hiddenClass);
                }
                addClass([notifyMeDesc, notifyMeButton], hiddenClass);
            }
            const wishlistButton = queryFirst('.add-to-wish-list', responseContainer);
            if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
                removeClass(wishlistButton, 'added-to-wish-list');
                wishlistButton.disabled = false;
                const assistiveText = wishlistButton.getAttribute('data-assistive-text');
                wishlistButton.setAttribute('aria-label', assistiveText);
            }
            if (!hasClass(responseContainer, 'gift-card-main') && !document.getElementById('chooseBonusProductModal')) {
                const ssColorElement = queryFirst('.color-container .color-attribute.selected', responseContainer);
                const ssColor = (ssColorElement && ssColorElement.dataset.attrDisplayvalue) || 'unknown';
                const { type } = price;
                const pricesObject = type === 'range' ? price.min : price;
                const { sales, list } = pricesObject;
                const ssSalesPrice = (sales && sales.value.toString()) || 'unknown';
                const ssPrice = (list && list.value.toString()) || ssSalesPrice;
                updateProductData(ssColor, ssSize, ssPrice, ssSalesPrice, responseContainer);
                if (ssSize === 'unknown') {
                    handleColor(responseContainer);
                } else {
                    handleSize(responseContainer);
                }
            }
            if (selectedSizeElement) {
                const productSetModal = selectedSizeElement.closest('#productSetModal');
                if (productSetModal) {
                    const addAllToToteButton = queryFirst('.add-to-cart-global', productSetModal);
                    if (addAllToToteButton) {
                        const productsAvailability = queryAll('.product-availability', productSetModal).filter(item => !hasClass(item.closest('.product-detail'), 'hidden-set'));
                        const numberOfProducts = productsAvailability.length;

                        // Check to enable add to tote button, if atleast one product exists
                        let enable = true;
                        if (!numberOfProducts) {
                            enable = false;
                        }
                        addAllToToteButton.disabled = !enable;
                    }
                }
            }

            if (wishlistDisabled) {
                showStrikeHearts(responseContainer);
            }
            $.spinner().stop();
        });
    },
    selectPdpSizeAttribute: function () {
        $(document).on('click', '.pdp-container .size-btn', function (e) {
            e.preventDefault();

            const productContainer = this.closest('.product-detail');
            const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainer);
            const selectedColorId = selectedColorElement.dataset.attrValue;
            const selectedSizeValue = this.dataset.attrValue;
            onSizeChangeHandler.apply(this, [productContainer, selectedSizeValue]);
            updateProductInfo(selectedSizeValue, selectedColorId, productContainer, this);
            $body.trigger('product:pdpSizeSelected', {
                selectedColorElement,
                selectedSizeElement: this,
                productContainer
            });
        });
        $('body').on('product:afterAttributeChange', (e, response) => {
            const selectedSizeElement = queryFirst('.pdp-container .size-btn.selected');
            const selectedValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
            updateProductInfo(selectedValue, response.variantGroupId, response.container[0], selectedSizeElement);
        });

        const swatchEl = queryFirst('.pdp-container button.color-attribute .swatch-circle.selected');

        if (swatchEl) {
            const sizeElements = queryAll('.pdp-container .size-btn');

            if (sizeElements.length) {
                if (sizeElements.length === 1) {
                    queryFirst('.pdp-container .size-btn').click();
                } else {
                    const selectedSizeEl = queryFirst('.pdp-container .size-btn.selected');

                    if (selectedSizeEl) {
                        selectedSizeEl.click();
                    }
                }

                if (!queryFirst('.pdp-container .size-btn.not-purchasable')) {
                    const allSizesSoldOut = sizeElements.every((sizeElement) => hasClass(sizeElement, 'not-available'));

                    // In case if all product sizes sold out and Notify me box not showed
                    // we hide this variation from swatches and select first color variation that have
                    // available sizes
                    if (allSizesSoldOut) {
                        removeClass(swatchEl, 'selectable');

                        const allSelectableSwatches = queryAll('.pdp-container button.color-attribute .swatch-circle.selectable');
                        const notifyMeBoxHidden = queryFirst('.notify-me-box.d-none'); // PDP case
                        const notifyMeHidden = queryFirst('.notify-me-desc.d-none'); // Quick view case

                        if (allSelectableSwatches.length && (notifyMeBoxHidden !== null || notifyMeHidden !== null)) {
                            // Hide the selected swatch because it's out of stock
                            addClass(swatchEl.closest('li'), 'd-none');
                            allSelectableSwatches[0].click();
                        }
                    }
                }
            }
        }
    },

    updateAttributesAndDetails: function () {
        $('body').on('product:statusUpdate', function (e, data) {
            var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

            $productContainer
                .find('.description-and-detail .product-attributes')
                .empty()
                .html(data.attributesHtml);

            if (data.shortDescription) {
                $productContainer.find('.description-and-detail .description').removeClass('hidden-xl-down');
                $productContainer
                    .find('.description-and-detail .description .content')
                    .empty()
                    .html(data.shortDescription);
            } else {
                $productContainer.find('.description-and-detail .description').addClass('hidden-xl-down');
            }

            if (data.longDescription) {
                $productContainer.find('.description-and-detail .details').removeClass('hidden-xl-down');
                $productContainer
                    .find('.description-and-detail .details .content')
                    .empty()
                    .html(data.longDescription);
            } else {
                $productContainer.find('.description-and-detail .details').addClass('hidden-xl-down');
            }
        });
    },

    showSpinner: function () {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            const productSetModal = document.getElementById('productSetModal');
            const quickViewModal = document.getElementById('quickViewModal');
            if (productSetModal && hasClass(productSetModal, 'show')) {
                $(productSetModal)
                    .spinner()
                    .start();
            } else if (quickViewModal && hasClass(quickViewModal, 'show')) {
                $(quickViewModal)
                    .spinner()
                    .start();
            } else {
                $.spinner().start();
            }
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            $('div.availability', response.$productContainer)
                .attr('data-ready-to-order', response.product.readyToOrder)
                .attr('data-available', response.product.available);

            var availabilityValue = '';
            var availabilityMessages = response.product.availability.messages;
            if (response.product.readyToOrder) {
                availabilityMessages.forEach(message => {
                    availabilityValue += availabilityMessageTmpl(message);
                });
            }

            $('.availability-msg', response.$productContainer)
                .empty()
                .html(availabilityValue);

            if ($('.global-availability').length) {
                var allAvailable = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $('.global-availability')
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg')
                    .empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            }
        });
    },
    sizeChart: function () {
        var $prodSizeChart = $('.size-chart-collapsible');
        $('body').on('click', '.size-chart .size-chart-link', function (e) {
            e.preventDefault();
            const element = $(this);
            const url = element.data('href');
            const $lpSlideout = $('.lp-sizechart');
            const setStickyNav = queryFirst('.custom-set-detail-sticky-nav');
            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $prodSizeChart.append(data.content);
                    },
                    complete: function () {
                        toogleActiveSizeChartTab(element, $prodSizeChart);

                        const lastLinkEl = queryFirst('.return-policy-link');
                        if (lastLinkEl) {
                            lastLinkEl.addEventListener('keydown', handleLastLinkTab);
                        }
                    }
                });
            } else {
                toogleActiveSizeChartTab(element, $prodSizeChart);
            }
            if (setStickyNav) {
                addClass(setStickyNav, 'd-none');
            }
            $lpSlideout.trigger('shown.lp.sizeguide');
        });

        $('body').on('click touchstart', function (e) {
            if (e.target.matches('#sizechart-close') || (e.target.matches('.size-chart-bg') && $prodSizeChart.hasClass('active'))) {
                $prodSizeChart.removeClass('active');
                $('.lp-slideout').removeClass('lp-slideout-open');
                $body.removeClass(sizeChartClasses);
                queryFirst('.size-chart .size-chart-link').focus(); // set focus back to trigger element
            }
        });
    },

    focusChooseBonusProductModal: base.focusChooseBonusProductModal(),
    renderSizeElements: base.renderSizeElements,
    handleEarlyAccessPLPLockIcon: base.handleEarlyAccessPLPLockIcon(),
    handleEarlyAccessLockIcon: function () {
        const productContainer = queryFirst('.pdp-container');
        const productContainerEarlyAccessIcon = queryFirst('.loyalty-early-access-lock-container', productContainer);
        if (productContainerEarlyAccessIcon) {
            const { earlyAccessDate } = productContainerEarlyAccessIcon.dataset;
            const earlyAccessItem = isEarlyAccessElement(earlyAccessDate);
            base.handleEarlyAccessCta(productContainer, earlyAccessItem);
        }
    },
    handleOfflineProduct: function () {
        const offlineContainerEl = queryFirst('.offline-product-detail');
        if (offlineContainerEl) {
            const pid = offlineContainerEl.dataset.pid;
            const availabilityStatus = getNestedValue(window, `productInventory.variants[${pid}].availabilityStatus`);
            if (availabilityStatus === IN_STOCK) {
                const { search: currentSearch } = window.location;
                const TS_PARAM = 'timestamp=';
                if (!currentSearch.includes(TS_PARAM)) {
                    window.location.search = (currentSearch ? currentSearch + '&' : '') + TS_PARAM + new Date().getTime();
                }
            }
        }
    },
    handlePdpNonEarlyAccessLoyalty: function () {
        // Sign in/Create account modal for non-early access product

        const signInCreateAccountModal = queryFirst('#loyalty-early-access-register');
        const loyaltyEnrollNowModal = queryFirst('#loyalty-enrollnow-modal');
        const pdpSignInCreateAccountButton = queryAll('.pdp-non-early-access-login');
        const earlyAccessSignInInfo = queryAll('.early-access-sign-info');
        const pdpSignInInfo = queryFirst('.pdp-sign-in-info');
        const earlyAccessSignInText = queryFirst('.early-access-sign-in-text');
        const welcomeDialogEarlyAccessMsgEl = queryFirst('.js-loyalty-welcome-early-access-msg');
        const welcomeDialogMsgEl = queryFirst('.js-loyalty-welcome-msg');
        const joinButton = queryFirst('.join-now-button');
        const earlyAccessWelcomeCta = queryFirst('.loyalty-early-access-welcome-cta');
        const loyaltyWelcomeDialog = queryFirst('#loyalty-welcome-popup');
        const $nonEarlyAccessLogin = $('.pdp-non-early-access-login');

        $nonEarlyAccessLogin.on('click', function () {
            const button = this;
            handleEarlyAccessLogin(button);
            const { pdpSignInTab } = button.dataset;
            $(signInCreateAccountModal).modal('show');
            $(`.nav-tabs a[href="#${pdpSignInTab}"]`).tab('show');
            if (pdpSignInCreateAccountButton.length) {
                addClass(earlyAccessSignInInfo, HIDDEN_CLASS);
                addClass(earlyAccessSignInText, HIDDEN_CLASS);
                removeClass(pdpSignInInfo, HIDDEN_CLASS);
            }

            // return focus to trigger after modal is closed
            $(signInCreateAccountModal).one('hidden.bs.modal', () => button.focus());
        });

        if (joinButton) {
            joinButton.addEventListener('click', e => {
                e.preventDefault();
                $(loyaltyEnrollNowModal).modal('show');
                addClass(welcomeDialogMsgEl, HIDDEN_CLASS);
                removeClass(welcomeDialogEarlyAccessMsgEl, HIDDEN_CLASS);
            });
        }
        if (earlyAccessWelcomeCta) {
            earlyAccessWelcomeCta.addEventListener('click', function () {
                location.reload();
            });
        }
        $(loyaltyWelcomeDialog).off('hidden.bs.modal').on('hidden.bs.modal', function () {
            location.reload();
        });
    }
};
