'use strict';

var processInclude = require('base/util');
import detail from './product/detail';
import notifyMe from './components/notifyMe';

document.addEventListener('DOMContentLoaded', () => {
    processInclude(detail.selectSizeAttribute);
    processInclude(detail.afterAttributeSelect);
    processInclude(require('./product/quickView'));
    processInclude(notifyMe);
});
