'use strict';

var formValidation = require('base/components/formValidation');
const baseLogin = require('base/login/login');

const { queryFirst, addClass, removeClass, loadInput } = require('../domUtil');
const { formatPhoneNumber, getItemFromLocalStorage } = require('../util');
const registrationFormPhone = queryFirst('.registration-form-phone');
const loyaltyGuestReferralModal = queryFirst('#guest-friend-referral-popup');
const $body = $('body');
const checkoutContainer = queryFirst('#checkout-main');
const { HIDDEN_CLASS } = require('../constants');

window.addEventListener('load', (event) => {
    const formElement = queryFirst('.change-password-form');
    const referrerID = getItemFromLocalStorage('REFERRED_ID');
    if (referrerID && formElement) {
        loadInput(referrerID, formElement);
    }
    if (loyaltyGuestReferralModal) {
        $(loyaltyGuestReferralModal).modal('show');
    }
    if (checkoutContainer) {
        const guestCheckoutSignInModal = queryFirst('#guest-checkout-login');
        const cancelBtnRegistrationModal = queryFirst('.registration-right .cancel a', guestCheckoutSignInModal);
        if (cancelBtnRegistrationModal) {
            cancelBtnRegistrationModal.addEventListener('click', e => {
                e.preventDefault();
                $(guestCheckoutSignInModal).modal('hide');
            });
        }
        if (guestCheckoutSignInModal) {
            addClass(queryFirst('.account-need-help'), HIDDEN_CLASS);
            const registerLeft = queryFirst('.registration-left');
            const registerRight = queryFirst('.registration-right');
            removeClass(registerLeft, 'offset-md-2');
            addClass([registerLeft, registerRight], 'col-md-6');
        }
    }
    if (queryFirst('.add-to-loyalty-program-checkbox')) {
        $('form.registration').trigger('register:optin_load');
    }
});

/**
 * Enables the login button - to be called after this script loads to prevent early login button clicks.
 */
function enableLoginButton() {
    $body.off('login:dialogLoaded').on('login:dialogLoaded', () => {
        enableLoginButton();
    });

    const btn = queryFirst('.account-login-btn');
    if (btn) {
        btn.disabled = false;
    }
}

module.exports = {
    ...baseLogin,

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $body.spinner().start();
            form.trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $body.spinner().stop();
                    if (data.success) {
                        form.trigger('register:success');
                        if (checkoutContainer) {
                            //reloading the page when account is created from checkout page
                            location.reload();
                        } else {
                            //redirecting to my account when account is created from Login Page
                            location.href = data.redirectUrl;
                        }
                    } else {
                        formValidation(form, data);
                        form.trigger('register:error');
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $body.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        const resetPasswordDialog = $('#requestPasswordResetModal');

        resetPasswordDialog.on('hidden.bs.modal', function () {
            removeClass(queryFirst('.password-reset-section', this), 'request-success');
            const emailEl = document.getElementById('reset-password-email');
            emailEl.value = '';
            removeClass(emailEl, 'is-invalid');
        });

        $('.reset-password-form').on('submit', function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        // LP Customization start
                        addClass(queryFirst('.reset-password-request-modal .password-reset-section'), 'request-success');
                        // LP Customization end
                    }
                },
                error: function () {
                    form.spinner().stop();
                    resetPasswordDialog.modal('hide').one('hidden.bs.modal', () => {
                        $body.trigger('csrf:error');
                    });
                }
            });

            return false;
        });
    },
    handleLogin: function () {
        const loginBtnEl = queryFirst('.account-login-btn');
        const $loginFormEl = $('form.login');
        $loginFormEl.on('login:submit', function () {
            loginBtnEl.disabled = true;
        });

        $loginFormEl.on('login:error', function () {
            loginBtnEl.disabled = false;
        });
    }
};

formatPhoneNumber(registrationFormPhone);
enableLoginButton();
