/* global _etmc */
'use strict';

const hasEtmc = typeof _etmc !== 'undefined';

/**
 * Send trackCart event to SFMC
 * @param {Object} data data to send to SFMC
 */
export function trackCart(data) {
    if (hasEtmc && 'trackCart' in _etmc) {
        _etmc.trackCart(data);
    }
}

/**
 * Send trackPageView event to SFMC
 * @param {Object} data data to send to SFMC
 */
export function trackPageView(data) {
    if (hasEtmc && 'trackPageView' in _etmc) {
        _etmc.trackPageView(data);
    }
}

/**
 * Send trackWishlist event to SFMC
 * @param {Object} data data to send to SFMC
 */
export function trackWishlist(data) {
    if (hasEtmc && 'trackWishlist' in _etmc) {
        _etmc.trackWishlist(data);
    }
}

/**
 * Send setUserInfo event to SFMC
 * @param {Object} data data to send to SFMC
 */
export function setUserInfo(data) {
    if (hasEtmc && 'setUserInfo' in _etmc) {
        _etmc.setUserInfo(data);
    }
}
