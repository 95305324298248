'use strict';

const { queryFirst, queryAll, addClass, removeClass, hasClass, debounce } = require('../domUtil');

const SCROLLABLE_CONTENT_SELECTOR = queryAll('.scrollable-content');
const LEFT_ARROW_SELECTOR = '.scroll-left-arrow';
const RIGHT_ARROW_SELECTOR = '.scroll-right-arrow';

function initSpecificCarousel(scrollableContainerEl) {
    const parentEl = scrollableContainerEl.parentElement;

    // Attaching events on Left arrow click
    const leftArrowEl = queryFirst(LEFT_ARROW_SELECTOR, parentEl);
    if (leftArrowEl) {
        leftArrowEl.addEventListener('click', () => {
            scrollableContainerEl.scrollTo({
                left: Math.max(0, scrollableContainerEl.scrollLeft - scrollableContainerEl.offsetWidth),
                behavior: 'smooth'
            });
        });
    }

    // Attaching events on Right arrow click
    const rightArrowEl = queryFirst(RIGHT_ARROW_SELECTOR, parentEl);
    if (rightArrowEl) {
        rightArrowEl.addEventListener('click', () => {
            scrollableContainerEl.scrollTo({
                left: Math.min(scrollableContainerEl.scrollWidth, scrollableContainerEl.scrollLeft + scrollableContainerEl.offsetWidth),
                behavior: 'smooth'
            });
        });
    }

    // Hide left arrow on carousel initialization.
    addClass(leftArrowEl, 'hide');

    // Hide both the arrows, if the items are not scrollable.
    if (scrollableContainerEl.scrollWidth <= scrollableContainerEl.offsetWidth) {
        addClass(rightArrowEl, 'hide');
    } else {
        const callback = () => {
            const { scrollWidth, scrollLeft, offsetWidth } = scrollableContainerEl;
            const offsetValue = 5;
            if (scrollWidth - scrollLeft - offsetWidth < offsetValue) {
                // Hide the right arrow, if content is scrolled to right end.
                if (hasClass(leftArrowEl, 'hide')) {
                    removeClass(leftArrowEl, 'hide');
                }
                if (!hasClass(rightArrowEl, 'hide')) {
                    addClass(rightArrowEl, 'hide');
                }
            } else if (scrollLeft === 0) {
                // Hide the left arrow, if content is scrolled to left end.
                if (!hasClass(leftArrowEl, 'hide')) {
                    addClass(leftArrowEl, 'hide');
                }
                if (hasClass(rightArrowEl, 'hide')) {
                    removeClass(rightArrowEl, 'hide');
                }
            } else {
                // Display both the arrows, if content is scrolled neither of the ends.
                if (hasClass(leftArrowEl, 'hide')) {
                    removeClass(leftArrowEl, 'hide');
                }
                if (hasClass(rightArrowEl, 'hide')) {
                    removeClass(rightArrowEl, 'hide');
                }
            }
        };
        scrollableContainerEl.addEventListener('scroll', debounce(callback, 300));
    }
}

module.exports = {
    initCarousel: function() {
        SCROLLABLE_CONTENT_SELECTOR.forEach(scrollableContainerEl => {
            initSpecificCarousel(scrollableContainerEl);
        });
    },
    initSpecificCarousel: initSpecificCarousel
};
