// OOTB Code
'use strict';
const { queryFirst, addClass, queryAll, isInViewport, removeClass, hasClass, scrollTo } = require('./domUtil');
const { errorTextTemplate } = require('./templates');
const formHelpers = require('./checkout/formErrors');
const checkoutContainerEl = document.getElementById('checkout-main');
const checkoutContainer = queryFirst('.data-checkout-stage');
const showClass = 'show';

/**
 * Function to scroll to th first invalid input if it is not in view port
 * @param {HTMLElement} formEl - Form to be validated
 */
function scrollToError(formEl) {
    const headerEl = queryFirst('.main-header');
    const headerHeight = headerEl ? headerEl.offsetHeight : 0;
    const invalidEl = $(formEl)
        .find('.is-invalid')
        .first();

    if (invalidEl[0] && !isInViewport(invalidEl[0])) {
        scrollTo(invalidEl.offset().top - headerHeight);
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (checkoutContainer) {
        const billingForm = queryFirst('.billing-address-form', checkoutContainer);
        if (this === billingForm) {
            return valid;
        }
    }
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        scrollToError(this);
        $(this)
            .find('input, select')
            .each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);
                }
            });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form)
        .find('.form-control.is-invalid')
        .removeClass('is-invalid');
}

// LP custom changes start
/**
 * Enable or disable button based on form validity status
 * @param {Object} button - button to be enabled or disabled
 * @param {Object} formElement - form to be validated
 */
function enableOrDisableButton(button, formElement) {
    button.disabled = formElement.checkValidity && !formElement.checkValidity();
    clearForm(formElement);
}
// LP custom changes end

/**
 * function returns validation message basedd on fields invalid state
 * @returns {string} Validation message
 */
function getValidationMessage() {
    let { validationMessage } = this;
    const $this = $(this);
    const patternMismatchValue = $this.data('pattern-mismatch');
    const rangeErrorValue = $this.data('range-error');
    const missingErrorValue = $this.data('missing-error');
    const badInputErrorValue = $this.data('bad-input-error');
    const stepMismatchErrorValue = $this.data('step-mismatch-error');
    const typeMismatchErrorValue = $this.data('type-mismatch-error');
    const { patternMismatch, rangeOverflow, rangeUnderflow, tooLong, tooShort, valueMissing, badInput, stepMismatch, typeMismatch } = this.validity;

    addClass(this, 'is-invalid');
    if (patternMismatch && patternMismatchValue) {
        validationMessage = patternMismatchValue;
    } else if ((rangeOverflow || rangeUnderflow) && rangeErrorValue) {
        validationMessage = rangeErrorValue;
    } else if ((tooLong || tooShort) && rangeErrorValue) {
        validationMessage = rangeErrorValue;
    } else if (valueMissing && missingErrorValue) {
        validationMessage = missingErrorValue;
    } else if (badInput && badInputErrorValue) {
        validationMessage = badInputErrorValue;
    } else if (stepMismatch && stepMismatchErrorValue) {
        validationMessage = stepMismatchErrorValue;
    } else if (typeMismatch && typeMismatchErrorValue) {
        validationMessage = typeMismatchErrorValue;
    }

    return validationMessage;
}

/**
 * Displays invalid forms error messages
 * @param {jQuery.event} e - Form invalid event object
 */
function onFormInvalidHandler(e) {
    e.preventDefault();
    this.setCustomValidity('');
    if (!this.validity.valid) {
        $('.btn-show-details').trigger('click');
        $(this)
            .parents('.form-group, .input-group')
            .find('.invalid-feedback')
            .text(getValidationMessage.apply(this));
        if (!checkoutContainerEl) {
            scrollToError(this.form);
        }
    }
}

/**
 * Form submission event handler
 * @param {jQuery.event} e - Form submit event object
 * @returns {boolean} true if the form is valid
 */
function onFormSubmit(e) {
    return validateForm.call(this, e);
}

/**
 * Function to update payment fields validation
 * @param {boolean} isRequired - describs payment fields required or not
 */
function updatePaymentValidation(isRequired) {
    queryFirst('input[name="dwfrm_billing_creditCardFields_cardNumber"]', checkoutContainerEl).required = isRequired;
    queryFirst('input[name="dwfrm_billing_creditCardFields_cardType"]', checkoutContainerEl).required = isRequired;
    queryFirst('input[name="dwfrm_billing_creditCardFields_securityCode"]', checkoutContainerEl).required = isRequired;
    document.getElementById('expirationMonth').required = isRequired;
    document.getElementById('expirationYear').required = isRequired;
}

/**
 * Function to check if the password and confirm password matches in form
 * @param {string} passwordFieldId - ID of the password form field
 * @param {boolean} isPasswordMatched - describes if password matched
 * @param {boolean} isValidForm - describes if the form is valid
 * @return {Object} - updated form validity and password match
 */
function checkPasswordMatch(passwordFieldId, isPasswordMatched, isValidForm, $this) {
    const newPasswordValue = queryFirst(passwordFieldId).value;
    const confirmPasswordValue = $this.value;
    let isPasswordMatches = isPasswordMatched;
    let isValidForms = isValidForm;
    if (confirmPasswordValue && newPasswordValue && confirmPasswordValue !== newPasswordValue) {
        isPasswordMatches = false;
        isValidForms = false;
    }
    return { isPasswordMatches, isValidForms };
}

/**
 * Runs validation on all INPUT elements within the specified containing element.
 * @param {element} container - The containing element to validate all inputs within
 */
function validateInputs(container) {
    if (!container) return;

    container.querySelectorAll('input').forEach(element => element.checkValidity());
}

module.exports = {
    invalid: function () {
        $('form input, form textarea, form select').on('invalid', onFormInvalidHandler);
    },

    ajaxFormInvalid: function (formElSelector) {
        if (!formElSelector) return;
        $(`${formElSelector} input, ${formElSelector} textarea, ${formElSelector} select`).on('invalid', onFormInvalidHandler);
    },

    submit: function () {
        $('form').on('submit', onFormSubmit);
    },

    ajaxFormSubmit: function (formElSelector) {
        if (!formElSelector) return;
        $(`${formElSelector}`).on('submit', onFormSubmit);
    },

    validateCheckoutForm: function () {
        // LP custom changes start
        if (checkoutContainer) {
            const guestCustomerForm = queryFirst('.guest-form', checkoutContainer);
            const registeredCustomerForm = queryFirst('.registered-form', checkoutContainer);
            const shippingForm = queryFirst('.shipping-form', checkoutContainer);
            const billingForm = queryFirst('.billing-address-form', checkoutContainer);
            const submitShippingBtn = queryFirst('.submit-shipping', checkoutContainer);
            const billingAddress = queryFirst('.billing-address', billingForm);
            const reauthForm = queryFirst('.reauth-payment-form', checkoutContainer);

            if (submitShippingBtn !== null) {
                submitShippingBtn.disabled = false;
            }
            // LP custom changes end

            $('.guest-form, .registered-form, .shipping-form, .billing-address-form')
                .find('input, textarea')
                .on('blur', function () {
                    if (this.validity.valid) {
                        removeClass(this, 'is-invalid');
                    } else {
                        $(this)
                            .parents('.form-group, .input-group')
                            .find('.invalid-feedback')
                            .text(getValidationMessage.apply(this));
                    }
                });
            $('.shipping-form, .billing-address-form')
                .find('select, input[type="radio"], input[type="checkbox"]')
                .on('change', function () {
                    if (this.validity.valid) {
                        removeClass(this, 'is-invalid');
                    } else {
                        $(this)
                            .parents('.form-group')
                            .find('.invalid-feedback')
                            .text(getValidationMessage.apply(this));
                    }
                });
            if (!reauthForm) {
                const submitCustomerBtn = queryFirst('.submit-customer', checkoutContainer);
                const submitCustomerLoginBtn = queryFirst('.submit-customer-login', checkoutContainer);
                if (submitCustomerBtn) {
                    submitCustomerBtn.addEventListener('click', function (event) {
                        if (guestCustomerForm.checkValidity && !guestCustomerForm.checkValidity()) {
                            event.stopPropagation();
                            scrollToError(guestCustomerForm);
                        }
                    });
                }
                if (submitCustomerLoginBtn) {
                    submitCustomerLoginBtn.addEventListener('click', function (event) {
                        if (registeredCustomerForm.checkValidity && !registeredCustomerForm.checkValidity()) {
                            event.stopPropagation();
                            scrollToError(registeredCustomerForm);
                        }
                    });
                }

                shippingForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    setTimeout(function () {
                        submitShippingBtn.click();
                    }, 0);
                });

                submitShippingBtn.addEventListener('click', function (event) {
                    const shippingForm = queryFirst('.shipping-form');
                    const errorElement = queryFirst('.error-message');
                    const { shippingError } = this.dataset;
                    const { addressMode } = shippingForm.dataset;
                    const { customerType } = checkoutContainer.dataset;
                    const giftCheckbox = queryFirst('.custom-control-input.gift');
                    const giftMessageContainer = queryFirst('.gift-message', shippingForm);
                    const giftMessageEl = document.getElementById('giftMessage');

                    removeClass(errorElement, showClass);

                    if (customerType === 'registered') {
                        if (addressMode === 'new' || addressMode === 'details') {
                            if (shippingForm.checkValidity && !shippingForm.checkValidity()) {
                                event.stopPropagation();
                                errorElement.innerHTML = errorTextTemplate(shippingError);
                                addClass(errorElement, showClass);
                                scrollTo(0);
                            }
                        } else if (giftMessageEl && !giftMessageEl.validity.valid) {
                            addClass(giftMessageEl, 'is-invalid');
                            event.stopPropagation();
                        } else {
                            removeClass(giftMessageEl, 'is-invalid');
                        }
                    } else if (shippingForm.checkValidity && !shippingForm.checkValidity()) {
                        event.stopPropagation();
                        errorElement.innerHTML = errorTextTemplate(shippingError);
                        addClass(errorElement, showClass);
                        scrollTo(0);
                    }
                    if (giftCheckbox.checked && !giftMessageEl.required) {
                        giftMessageEl.required = true;
                        giftMessageEl.checkValidity();
                        removeClass(giftMessageContainer, 'd-none');
                        event.stopPropagation();
                    }
                });

                const submitPaymentBtn = queryFirst('.submit-payment', checkoutContainer);

                submitPaymentBtn.addEventListener('click', function (event) {
                    const { customerType } = checkoutContainer.dataset;
                    const isPaymentDisabled = hasClass(queryFirst('.payment-information'), 'disabled-section');
                    const isCreditTabActive = hasClass(queryFirst('.credit-card-tab', checkoutContainer), 'active');
                    const isBoltTabActive = hasClass(queryFirst('.bolt-tab', checkoutContainer), 'active');
                    const isNewPayment = $('.payment-information').data('is-new-payment');
                    const isRequired = !isPaymentDisabled && (isCreditTabActive || isBoltTabActive);
                    const checkIsBoltEnabledEl = queryFirst('.check-is-bolt-enabled');
                    const isBoltEnabled = (checkIsBoltEnabledEl && checkIsBoltEnabledEl.value === 'true') || false;
                    const savedPaymentCvv = queryFirst('.saved-payment-security-code');

                    updatePaymentValidation(isRequired);
                    let isCardExpired = false;
                    if (isBoltEnabled && isBoltTabActive) {
                        updatePaymentValidation(isRequired && isNewPayment);
                        if (!isPaymentDisabled && isNewPayment) {
                            const creditCardExpirationMonthEl = queryFirst('#expirationMonth');
                            const creditCardExpirationYearEl = queryFirst('#expirationYear');
                            const { cardExpiredError } = creditCardExpirationMonthEl.dataset;
                            const boltCardExpireMonth = creditCardExpirationMonthEl.value;
                            const boltCardExpireYear = creditCardExpirationYearEl.value;
                            const today = new Date();
                            const expDate = new Date();
                            expDate.setFullYear(boltCardExpireYear, boltCardExpireMonth, 0);
                            if (boltCardExpireYear && boltCardExpireMonth && expDate < today) {
                                const boltExpiredCardError = { dwfrm_billing_creditCardFields_expirationMonth: cardExpiredError, dwfrm_billing_creditCardFields_expirationYear: cardExpiredError };
                                formHelpers.loadFormErrors('.payment-form', boltExpiredCardError);
                                isCardExpired = true;
                            }
                        }

                        // In case of LP reg user having saved cards and payment method is Bolt Pay
                        // then CVV should not be a required field for LP saved cards
                        if (savedPaymentCvv) {
                            savedPaymentCvv.required = false;
                        }
                    } else if (customerType === 'registered') {
                        if (savedPaymentCvv) {
                            savedPaymentCvv.required = isRequired && !isNewPayment;
                        }
                        updatePaymentValidation(isRequired && isNewPayment);
                    }
                    if ((billingForm.checkValidity && !billingForm.checkValidity()) || isCardExpired) {
                        scrollToError(billingForm);
                        event.stopPropagation();
                    }
                });

                billingForm.addEventListener('submit', function (event) {
                    event.preventDefault();
                    setTimeout(function () {
                        // if the payment button is hidden, it's because CC is not active
                        // and therefore we shouldn't click it
                        if (!hasClass(submitPaymentBtn, 'd-none')) {
                            submitPaymentBtn.click();
                        }
                    }, 0);
                });

                queryFirst('#billingAddressSelector').addEventListener('change', () => {
                    if (checkoutContainer.dataset.checkoutStage === 'payment') {
                        clearForm(billingAddress.closest('form'));
                        validateInputs(billingAddress);
                    }
                });

                const addressLinks = queryAll('.billing-address-block .address-links a');

                addressLinks.forEach(link => {
                    link.addEventListener('click', () => {
                        clearForm(billingAddress.closest('form'));
                    });
                });

                if (checkoutContainer.dataset.checkoutStage === 'payment') {
                    $(() => {
                        validateInputs(billingAddress);
                    });
                }
            } else {
                const submitReauthBtn = queryFirst('.submit-payment-button', checkoutContainer);
                submitReauthBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    $.spinner().start();
                    submitReauthBtn.disabled = true;
                    updatePaymentValidation(true);
                    if (billingForm.checkValidity && !billingForm.checkValidity()) {
                        scrollToError(billingForm);
                        submitReauthBtn.disabled = false;
                        $.spinner().stop();
                        event.stopPropagation();
                    } else {
                        billingForm.submit();
                    }
                });
            }
        }
    },

    enableFormSubmitButton: function () {
        const formElements = queryAll('.enable-form-validate');
        const selectorContainer = $('.valid-password-info li');
        const notMatchedClass = 'pwd-criteria-not-matched';
        const matchedClass = 'pwd-criteria-matched';
        formElements.forEach(form => {
            const button = queryFirst('.enable-button-onvalidate', form);
            if (form && button) {
                const $form = $(form);
                const callback = function () {
                    const isConfirmPassword = this.id === 'newPasswordConfirm';
                    const isNewPassword = this.id === 'newPassword';
                    const isConfirmRegistrationPassword = this.id === 'registration-form-password-confirm';
                    const isRegistrationPassword = this.id === 'registration-form-password';
                    let isPasswordMatched = true;
                    let isValidForm = true;

                    if (isConfirmPassword || isConfirmRegistrationPassword) {
                        const passwordFieldId = isConfirmPassword ? '#newPassword' : '#registration-form-password';
                        const confirmPasswordMatches = checkPasswordMatch(passwordFieldId, isPasswordMatched, isValidForm, this);
                        isPasswordMatched = confirmPasswordMatches.isPasswordMatches;
                        isValidForm = confirmPasswordMatches.isValidForms;
                    }

                    if (this.validity.valid && isValidForm) {
                        removeClass(this, 'is-invalid');
                        if (isNewPassword || isRegistrationPassword) {
                            selectorContainer.removeClass(notMatchedClass);
                            selectorContainer.addClass(matchedClass);
                        }
                    } else {
                        const $this = $(this);
                        const thisValue = $this.val();
                        if ($form.hasClass('change-password-form') && (isNewPassword || isRegistrationPassword) && thisValue) {
                            selectorContainer.each(function (i) {
                                const item = selectorContainer[i];
                                const regexPattern = item.dataset.regexpattern;
                                const regexValue = new RegExp(regexPattern);
                                if (regexValue.test(thisValue)) {
                                    removeClass(item, notMatchedClass);
                                    addClass(item, matchedClass);
                                } else {
                                    removeClass(item, matchedClass);
                                    addClass(item, notMatchedClass);
                                }
                            })
                            selectorContainer.closest('ul').addClass('padding-none');
                            $this.parents('.form-group').find('.invalid-feedback').text('');
                        } else {
                            if (!isPasswordMatched) {
                                $this.parents('.form-group').find('.invalid-feedback').text(this.dataset.mismatchError);
                                addClass(this, 'is-invalid');
                            } else {
                                $this.parents('.form-group').find('.invalid-feedback').text(getValidationMessage.apply(this));
                            }
                        }
                    }
                };
                $form.find('input, textarea').on('blur', callback);
                $form.find('#newPassword, #registration-form-password').on('keyup', callback);
                $form.find('select, input[type="radio"], input[type="checkbox"]').on('change', callback);
                $form.on('change', '.g-recaptcha-response', callback);
            }
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            if (!$('.reauth-payment-form').length) {
                clearForm($(this).parents('form'));
            }
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        validateInputs,
        clearForm,
        enableOrDisableButton
    }
};
