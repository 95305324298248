/**
 * OOTBS SFRA JS code
 * DRP-180 updated addToCart product set code on line 772 for custom designs
 */

'use strict';
const focusHelper = require('base/components/focus');
import { queryFirst, queryAll, addClass, removeClass, hasClass, remove, renderFragment, toFormData } from '../domUtil';
import { formatMessage, getJSON, getNestedValue, tokenize } from '../util';
import { updateProductData, handleColor } from 'fitpredictor/product/secretsauce';
import { getStoredDataLayerInfo } from 'analytics/util/analytics-util';
import { P_PRODUCT_LIST_KEY, P_PROMO_NAME_KEY, P_PROMO_CREATIVE_NAME } from 'analytics/util/constants';
import { setWishlistProductId, showStrikeHearts } from '../wishlist/helper';

const { trackPageView } = require('../components/etmc');
const { toggleSelectSizeInfo, isEarlyAccessElement } = require('./helper');
const { swatchNameTemplate, sizeOptionTemplate, sizeButtonTemplate, gwpSizeTemplate, dialogTemplate, loyalty: loyaltyTemplates } = require('../templates');
const $body = $('body');
const $gwpDialog = $('#chooseBonusProductModal');
const hiddenClass = 'd-none';
const readyClass = 'ready-to-add';
const selectedProductClass = 'selected-product';
const notAvailable = 'not-available';
const { updateVisibilityOfLowInventoryMsg } = require('../components/shippingPreference/shippingPreferenceHelper');
const isFitPredictorEnabled = !!queryFirst('#fitPredictorEnabled');
const { PRODUCT_DETAIL_CONTAINER_SELECTOR } = require('../components/shippingPreference/constants');
const { HIDDEN_CLASS } = require('../constants');
const loyaltyProfile = getNestedValue(window, 'lillyUtils.profile') || {};
const { isLoyaltyProgramMember, isLoyaltyAuthenticated, isLoyaltyEnabled } = loyaltyProfile;
let observer;
let isContentModulesFetched = false;

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').attr('data-pid');
    } else if ($('.quickview-container').length && !$('.product-set').length) {
        pid = $($el).closest('.quickview-container').find('.product-quickview').attr('data-pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').first().text();
    } else {
        pid = $($el).closest('.product-detail').find('.product-id').first().text();
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length ? $el.closest('.product-detail').find('.quantity-select') : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        const { bonusVariationUrl, url } = attrValue;

        if (isChoiceOfBonusProducts) {
            if (bonusVariationUrl) {
                $swatchButton.attr('data-url', bonusVariationUrl);
            } else {
                $swatchButton.removeAttr('data-url');
            }
        } else if (url) {
            $swatchButton.attr('data-url', url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    const $bonusProductItem = $productContainer.parent('.bonus-product-item');
    if ($bonusProductItem.length) {
        const sizes = attr.values;
        const sizeContainer = queryFirst('.selected-size-container', $bonusProductItem[0]);
        if (!sizes.length || !sizeContainer) {
            return;
        }

        if (sizes.length === 1) {
            sizeContainer.innerHTML = formatMessage(gwpSizeTemplate.singleSize, sizes[0].displayValue);
        } else {
            const loopStatus = sizeContainer.dataset.loopStatus;
            const resetUrl = attr.resetUrl;
            const selectLabel = sizeContainer.dataset.selectLabel;
            const selectId = `bonus-size-${loopStatus.count || '1'}`;

            let sizeOptions = formatMessage(gwpSizeTemplate.multiDefaultSizeOption, resetUrl, selectLabel);
            sizeOptions += sizes
                .map(function (size) {
                    const selected = size.selected ? ' selected ' : '';
                    const className = !size.inStock || size.forceOutOfStock ? notAvailable : '';
                    if (className) {
                        const outOfStockMsg = sizeContainer.dataset.outOfStock;
                        size.displayValue += ' - ' + outOfStockMsg;
                    }
                    return formatMessage(gwpSizeTemplate.multiSizeOption, size.url, size.value, selected, size.displayValue, className, className ? 'disabled' : '');
                })
                .join('');
            sizeContainer.innerHTML = formatMessage(gwpSizeTemplate.multiSize, selectId, sizeOptions);
        }
    } else {
        var $attr = '[data-attr="' + attr.id + '"]';
        const selectSizeEl = $productContainer.find('.select-size')[0];
        let selectedValue = '';
        if (selectSizeEl) {
            $(selectSizeEl).find('option').not(':first').remove();
            $(selectSizeEl).find('option:first').attr('value', attr.resetUrl);
        }
        attr.values.forEach(function (attrValue) {
            if (selectSizeEl) {
                const assistiveElement = queryFirst('.selected-assistive-text', selectSizeEl.closest('.size-list'));
                assistiveElement.textContent = selectSizeEl.selectedOptions[0].dataset.attrValue;
                if (!attrValue.isOffline) {
                    selectSizeEl.innerHTML += sizeOptionTemplate(attrValue.url, attrValue.value);
                }
                if (attrValue.selected) {
                    selectedValue = attrValue.value;
                }
            } else {
                var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
                const sizeListItemEl = $attrValue.closest('.size-list');
                if (!attrValue.isOffline) {
                    sizeListItemEl.removeClass(hiddenClass);
                } else {
                    sizeListItemEl.addClass(hiddenClass);
                }
                if ($attrValue.length > 0) {
                    $attrValue.val(attrValue.url).attr('data-attr-url', attrValue.url).removeClass(notAvailable);
                    const assistiveElement = queryFirst('.selected-assistive-text', $attrValue[0].closest('.size-list'));
                    assistiveElement.textContent = '';
                    const { selectedText, outOfStock } = assistiveElement.dataset;
                    if (hasClass($attrValue[0], 'selected')) {
                        assistiveElement.textContent += selectedText;
                    }
                    if (!attrValue.inStock || attrValue.forceOutOfStock) {
                        $attrValue.addClass(notAvailable);
                        assistiveElement.textContent += outOfStock;
                    }
                }
            }
        });
        if (selectSizeEl && selectedValue) {
            const optionEl = queryFirst('[data-attr-value="' + selectedValue + '"]', selectSizeEl);
            if (optionEl) {
                optionEl.selected = true;
            } else {
                selectSizeEl.selectedIndex = 0;
            }
        }
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    const { isDirectlyPurchasable } = response.product;
    const availabilityMsgEl = queryFirst('.availability-msg', $productContainer[0]);
    if (isDirectlyPurchasable === false && availabilityMsgEl) {
        availabilityValue = `<li><p class="availability-message-text" role="alert">${availabilityMsgEl.dataset.notPurchasable}</p></li>`;
    } else if (response.product.readyToOrder) {
        availabilityMessages.forEach(function (message) {
            availabilityValue += `<li><p class="availability-message-text" role="alert">
                ${message}
                </p></li>`;
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Handles the CTAs to be shown for Early Access Product
 * @param {jQuery} productContainer - DOM element for current product
 * @param {boolean} isEarlyAccessProduct - Is the product Early Access Product
 */
function handleEarlyAccessCta(productContainer, isEarlyAccessProduct) {
    const earlyAccessLockIcon = queryFirst('.loyalty-early-access-lock-container', productContainer);
    const earlyAccessWishlistIcon = queryFirst('.add-to-wish-list-container', productContainer);
    const earlyAccessCta = queryFirst('.loyalty-early-access-restricted', productContainer);
    const addToCartCta = queryFirst('.add-to-cart', productContainer);
    const earlyAccessPdpEl = queryFirst('.loyalty-product-pdp-badge', productContainer);
    if (isLoyaltyEnabled && earlyAccessCta) {
        if (earlyAccessPdpEl) {
            addClass([earlyAccessPdpEl, earlyAccessCta], hiddenClass);
        }
        if (isEarlyAccessProduct) {
            if (isLoyaltyProgramMember) {
                addClass([earlyAccessLockIcon, earlyAccessCta], HIDDEN_CLASS);
                if (earlyAccessWishlistIcon && addToCartCta) {
                    removeClass([earlyAccessWishlistIcon, addToCartCta], HIDDEN_CLASS);
                }
            } else {
                removeClass([earlyAccessLockIcon, earlyAccessCta], HIDDEN_CLASS);
                if (earlyAccessWishlistIcon && addToCartCta) {
                    addClass([earlyAccessWishlistIcon, addToCartCta], HIDDEN_CLASS);
                }
                const earlyAccessGuest = queryFirst('.early-access-guest', earlyAccessCta);
                const earlyAccessRegistered = queryFirst('.early-access-registered', earlyAccessCta);
                if (isLoyaltyAuthenticated) {
                    addClass(earlyAccessGuest, HIDDEN_CLASS);
                    removeClass(earlyAccessRegistered, HIDDEN_CLASS);
                } else {
                    removeClass(earlyAccessGuest, HIDDEN_CLASS);
                    addClass(earlyAccessRegistered, HIDDEN_CLASS);
                }
            }
            if (earlyAccessPdpEl) {
                removeClass(earlyAccessPdpEl, hiddenClass);
            }
        } else {
            if (earlyAccessWishlistIcon && addToCartCta) {
                removeClass([earlyAccessWishlistIcon, addToCartCta], hiddenClass);
            }
            addClass([earlyAccessLockIcon, earlyAccessCta], hiddenClass);
        }
    }
}
/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {boolean} isEarlyAccess - Check if the product is early access product
 * @param {boolean} earlyAccessText - Early Access badge text
 * @param {string} videoUrl - Selected variant group video url
 */
function createCarousel(imgs, $productContainer, isEarlyAccess, earlyAccessText, videoUrl) {
    var carousel = $productContainer.find('.carousel:not(.tile-carousel)');
    $(carousel).carousel('dispose');
    var carouselId = $(carousel).attr('id');

    const displayCounter = $productContainer.hasClass('product-quickview');

    $(carousel)
        .empty()
        .append(
            '<ol class="carousel-indicators"></ol><div class="carousel-inner" role="listbox"></div><a class="carousel-control-prev" href="#' +
            carouselId +
            '" role="button" data-slide="prev"><span class="fa icon-prev" aria-hidden="true"></span><span class="sr-only">' +
            $(carousel).data('prev') +
            '</span></a><a class="carousel-control-next" href="#' +
            carouselId +
            '" role="button" data-slide="next"><span class="fa icon-next" aria-hidden="true"></span><span class="sr-only">' +
            $(carousel).data('next') +
            '</span></a>'
        );
    let i;
    for (i = 0; i < imgs.length; i++) {
        let counterHtml = '';
        if (displayCounter && imgs.length > 1) {
            counterHtml = `<span class='carousel-counter'>${i + 1} ${'/'} ${imgs.length}</span>`;
        }

        const imageZoomUrl = carousel.data('image');
        const imagePresetUrl = carousel.data('preset');
        const enableCache = carousel.data('cache');

        let dataSrc = '';
        if (imgs[i].url.includes(imagePresetUrl)) {
            dataSrc = imgs[i].url.replace(imagePresetUrl, imageZoomUrl);
        } else {
            dataSrc = imgs[i].url + '?$' + imageZoomUrl + '$';
        }
        $(
            '<div class="carousel-item">' +
            (i === 0 && earlyAccessText ? formatMessage(loyaltyTemplates.carouselBadge, earlyAccessText) : '') +
            '<img srcset="' +
            imgs[i].srcset +
            '" src="' +
            imgs[i].url +
            '" data-src="' +
            dataSrc +
            (enableCache ? '&cache_img=true' : '') +
            '" class="d-block img-fluid" alt="' +
            imgs[i].alt +
            ' image number ' +
            parseInt(imgs[i].index, 10) +
            '" title="' +
            imgs[i].title +
            '" itemprop="image" /> ' +
            counterHtml +
            '</div>'
        ).appendTo($(carousel).find('.carousel-inner'));
        $(
            '<li data-target="#' + carouselId + '" data-slide-to="' + i + '" class=""><button class="pip-content" role="button" aria-label="Product Carousel Image ' + (i + 1) + '"><span class="sr-only">' + imgs[i].alt + '' + i + '</span></button></li>'
        ).appendTo($(carousel).find('.carousel-indicators'));
    }
    const productContainer = $productContainer[0];
    const earlyAccessPdpEl = queryFirst('.loyalty-product-pdp-badge', productContainer);
    const addToWishlistIconPdp = queryFirst('.add-to-wish-list-container', productContainer);
    const loyaltyEarlyAccessEl = queryFirst('.loyalty-early-access-restricted', productContainer);
    const addToCartButtonPdp = queryFirst('.pdp-container .add-to-cart', productContainer);
    const pdpNonEarlyAccessLoginEl = queryFirst('.pdp-sign-in-create-account-section', productContainer);

    if (pdpNonEarlyAccessLoginEl) {
        const { isLoyaltyEnabled } = pdpNonEarlyAccessLoginEl.dataset;
        if (isLoyaltyEnabled === 'true' && !isEarlyAccess) {
            removeClass(pdpNonEarlyAccessLoginEl, hiddenClass);
        } else {
            addClass(pdpNonEarlyAccessLoginEl, hiddenClass);
        }
    }
    if (addToCartButtonPdp) {
        removeClass(addToCartButtonPdp, hiddenClass);
    }
    if (earlyAccessPdpEl && loyaltyEarlyAccessEl) {
        addClass([earlyAccessPdpEl, loyaltyEarlyAccessEl], hiddenClass);
    }
    removeClass(addToWishlistIconPdp, hiddenClass);
    if (isEarlyAccess) {
        const loyaltyEarlyAccessLockContainer = queryFirst('.primary-images .loyalty-early-access-lock-container', productContainer);
        if (loyaltyEarlyAccessLockContainer) {
            addClass(loyaltyEarlyAccessLockContainer, hiddenClass);
        }
    }
    handleEarlyAccessCta(productContainer, isEarlyAccess);

    const firstImageElement = queryFirst('.carousel-inner .carousel-item img.img-fluid', $productContainer[0]);
    if (firstImageElement) {
        firstImageElement.addEventListener('load', () => {
            $body.trigger('product:imageLoad', {
                container: $productContainer[0]
            });
        });
    }
    if (videoUrl) {
        // LP customizations start
        const productContainer = $productContainer[0];
        const videoTemplate = queryFirst('.product-video-template').cloneNode(true);
        const templateVideoElement = queryFirst('.carousel-item video', videoTemplate);
        if (templateVideoElement) {
            templateVideoElement.setAttribute('poster', imgs[0].url);
            queryAll('source', templateVideoElement).forEach(eachElement => {
                eachElement.setAttribute('src', videoUrl);
            });
            queryFirst('.carousel-inner', carousel[0]).appendChild(queryFirst('.carousel-item', videoTemplate));
            queryFirst('.carousel-item video', productContainer).load();
        }
        // LP customizations end

        $('<li data-target="#' + carouselId + '" data-slide-to="' + imgs.length + '" class=""><button class="pip-content" role="button" aria-label="Product Carousel Image ' + (i + 1) + '"></button></li>').appendTo(
            $(carousel).find('.carousel-indicators')
        );
    }
    $($(carousel).find('.carousel-item')).first().addClass('active');
    $($(carousel).find('.carousel-indicators > li')).first().addClass('active');
    if (imgs.length === 1) {
        $($(carousel).find('.carousel-indicators, a[class^="carousel-control-"]')).detach();
    }
    $(carousel).carousel();
    $($(carousel).find('.carousel-indicators')).attr('aria-hidden', true);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    const { variationAttributes, productType, readyToOrder, bonusVariationAtrributes, isFinalSale, isDirectlyPurchasable, earlyAccess } = response.product;
    const saleMessageEl = queryFirst('.on-sale-message', $productContainer[0]);
    if (saleMessageEl) {
        if (isFinalSale) {
            removeClass(saleMessageEl, hiddenClass);
        } else {
            addClass(saleMessageEl, hiddenClass);
        }
    }

    if (isChoiceOfBonusProducts) {
        if (bonusVariationAtrributes) {
            updateAttrs(bonusVariationAtrributes, $productContainer, response.resources);
        }
        if (productType === 'variant') {
            $productContainer.parent('.bonus-product-item').data('ready-to-order', readyToOrder);
        }
    } else if (variationAttributes) {
        updateAttrs(variationAttributes, $productContainer, response.resources);
        if (isDirectlyPurchasable === false) {
            const sizeElements = queryAll('.size-btn', $productContainer[0]);
            addClass(sizeElements, notAvailable);
        }
    }

    // Update primary images
    var primaryImageUrls = isChoiceOfBonusProducts ? response.product.images.checkout : response.product.images.large;

    const productContainer = $productContainer[0];
    if (isChoiceOfBonusProducts) {
        // LP customization changes start
        const { alt, url, srcset } = primaryImageUrls[0] || {};
        const productImageElement = queryFirst('.product-image', productContainer);
        productImageElement.setAttribute('src', url);
        productImageElement.setAttribute('srcset', srcset);
        productImageElement.setAttribute('alt', alt);
        // LP customization changes end
    } else if (hasClass(productContainer, 'custom-set-product')) {
        const setModalImages = response.product.images.ispu;
        if (setModalImages) {
            const { alt, url, srcset } = setModalImages[0] || {};
            const productImageElement = queryFirst('.custom-set-detail-modal-img img', productContainer);
            productImageElement.setAttribute('src', url);
            productImageElement.setAttribute('srcset', srcset);
            productImageElement.setAttribute('alt', alt);
        }
    } else {
        createCarousel(primaryImageUrls, $productContainer, earlyAccess && earlyAccess.isEarlyAccessProduct);
    }

    // Updated logic here
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const selectedSwatch = queryFirst('.swatch-circle.selected', productContainer);
    let selectedSwatchName = '';
    if (selectedSwatch) {
        selectedSwatchName = selectedSwatch.dataset.swatchName;
    }
    if (swatchNameElement) {
        swatchNameElement.textContent = selectedSwatchName;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, selectedSwatchName);
        const selectedSwatchElement = queryFirst('.selected-swatch', productContainer);
        if (selectedSwatchElement) {
            selectedSwatchElement.innerHTML = swatchHtml;
        }
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        let $priceSelector = $('.prices .price', $productContainer);
        if (!$priceSelector.length) $priceSelector = $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    const promotionElement = queryFirst('.promotions', productContainer);
    if (promotionElement) {
        promotionElement.innerHTML = response.product.promotionsHtml;
    }

    const promotionsPopover = queryFirst('.promotions-info-popover .promotions-info-popover-content');
    $('.custom-set-detail').find('.promotions-info').popover({
        trigger: 'click',
        placement: 'bottom',
        html: true,
        content: promotionsPopover
    });
    $('[data-toggle="popover"]').off('shown.bs.popover').on('shown.bs.popover', function (e) {
        var target = e.target;
        $(document).one('click', function (clickEvent) {
            var clicked = clickEvent.target;
            if (clicked !== target && clicked.parentElement !== target) {
                $(target).trigger('click');
            }
        });
    });

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else if ($productContainer.closest('#productSetModal').length > 0) {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', {
                product: response.product,
                $productContainer: $productContainer
            })
            .trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    const $bonusProductItem = $productContainer.parent('.bonus-product-item');
    if (!($bonusProductItem.length > 0)) {
        var optionsHtml = quantities
            .map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' + selected + '>' + quantity.value + '</option>';
            })
            .join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Object} selectedSizeElement - DOM element for selected size button
 */
function attributeSelect(selectedValueUrl, $productContainer, selectedSizeElement) {
    if (selectedValueUrl) {
        $body.trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        // Updated logic here
        const $choiceOfBonusProductEl = $productContainer.closest('.choice-of-bonus-product');
        if ($choiceOfBonusProductEl.length) {
            $productContainer.spinner().start();
        }

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $body.trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer,
                    selectedSizeElement
                });
                if ($choiceOfBonusProductEl.length) {
                    $choiceOfBonusProductEl.trigger('bonus:afterAttributeSelect');
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function selectColorAttribute(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $body.trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        // Updated logic here
        const $choiceOfBonusProductEl = $productContainer.closest('.choice-of-bonus-product');
        if ($choiceOfBonusProductEl.length) {
            $productContainer.spinner().start();
        }

        getJSON(
            selectedValueUrl,
            'GET',
            null,
            data => {
                const { options, quantities, uuid } = data.product;
                handleVariantResponse(data, $productContainer);
                updateOptions(options, $productContainer);
                updateQuantities(quantities, $productContainer, uuid);
                const selectedSizeElement = queryFirst('.size-btn.selected', $productContainer[0]);
                $body.trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer,
                    selectedSizeElement
                });
                if ($choiceOfBonusProductEl.length) {
                    const sizeEl = queryFirst('.select-size', $choiceOfBonusProductEl[0]);
                    if (sizeEl) sizeEl.selectedIndex = 0;
                    $choiceOfBonusProductEl.trigger('bonus:afterAttributeSelect');
                }
                const swatchContainer = queryFirst('.color-container', $productContainer[0]);
                const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
                const selectedSwatchElement = queryFirst('.swatch-circle.selected', $productContainer[0]);
                const assistiveTextElement = queryFirst('.selected-assistive-text', selectedSwatchElement.closest('.color-list'));
                assistiveElements.forEach(eachElement => {
                    eachElement.textContent = '';
                });
                assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;
            },
            () => {
                $.spinner().stop();
            }
        );
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} data - Shop the print data
 * @param {Object} productContainer - DOM container for a given product
 */
function updateShopThePrint(data, productContainer) {
    const shopThePrintElement = queryFirst('.shop-the-print', productContainer);
    if (shopThePrintElement) {
        if (data) {
            shopThePrintElement.outerHTML = data;
        } else {
            shopThePrintElement.outerHTML = '<div class="shop-the-print"></div>';
        }
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {int} price - product sale price
 * @param {int} standardPrice - product standard price
 * @param {Object} productContainer - DOM container for a given product
 */
function updateProductPrice(price, standardPrice, productContainer) {
    const priceTemplate = document.getElementById('price-template').cloneNode(true);
    const priceSection = queryFirst('.prices .price', productContainer);
    const priceContainer = queryFirst('.price-section', priceTemplate);
    const strikeThroughContainer = queryFirst('.strike-through-section', priceContainer);
    const priceElement = queryFirst('.strike-through-price', strikeThroughContainer);
    const salesContainer = queryFirst('.sales', priceContainer);
    const salesPriceElement = queryFirst('.price-value', salesContainer);
    let fixedPrice = price;
    let fixedStandardPrice = standardPrice;

    // Fix decimal places for non-whole-number prices
    if (price % 1 !== 0) {
        fixedPrice = price.toFixed(2);
    }
    if (standardPrice % 1 !== 0) {
        fixedStandardPrice = standardPrice.toFixed(2);
    }

    removeClass(strikeThroughContainer, hiddenClass);
    priceElement.textContent = `$${fixedStandardPrice}`;
    salesPriceElement.textContent = `$${fixedPrice}`;
    if (price === standardPrice) {
        addClass(strikeThroughContainer, hiddenClass);
    }
    priceSection.innerHTML = priceTemplate.innerHTML;
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} sizes - sizes object
 * @param {Object} productContainer - DOM container for a given product
 * @param {Object} variantsList - Variations list data
 */
function updateSizeElements(sizes, productContainer, variantsList) {
    const isQuickView = hasClass(productContainer, 'cached-quick-view');
    const { variants } = isQuickView ? window.quickviewProductInfo.productInventory : window.productInventory;
    const sizeContainer = queryFirst('.size-container', productContainer);

    if (!sizeContainer) return;

    const { selectedText, outOfStock, label, describedby, attrValue } = sizeContainer.dataset;
    let sizeButtonHtml = '';

    variantsList.forEach((eachVariant, index) => {
        const eachSize = sizes[eachVariant];
        const sizeElement = queryFirst('[data-attr="size"] [data-attr-value="' + eachVariant + '"]', productContainer);
        const sizeId = eachSize.ID;
        const { forceOutOfStock } = eachSize;
        const inventoryData = variants[sizeId];
        const variantData = {
            value: eachVariant,
            forceOutOfStock,
            selected: sizeElement ? hasClass(sizeElement, 'selected') : false,
            inStock: inventoryData.availabilityStatus !== 'NOT_AVAILABLE'
        };
        const ariaLabelValue = label + ' ' + attrValue + ' ' + eachVariant;
        sizeButtonHtml += sizeButtonTemplate(variantData, ariaLabelValue, describedby + eachVariant + index, selectedText, outOfStock);
    });
    remove(queryAll('.size-list', sizeContainer));
    sizeButtonHtml += sizeContainer.innerHTML;
    sizeContainer.innerHTML = sizeButtonHtml;
}

/**
 * Function to update Ways to wear content based on the response
 * @param {string} groupId - selected variation group id
 * @param {string} variationUrl - url to fetch the content
 * @param {HTMLElement} waysToWearContainer - container with recommendations
 */
function updateContentModules(groupId, variationUrl, waysToWearContainer, entry) {
    if (isContentModulesFetched || !entry.isIntersecting) { return; }

    $(waysToWearContainer).spinner().start();
    $.ajax({
        url: variationUrl,
        method: 'post',
        data: {
            variationGroup: groupId
        }
    }).done(function (response) {
        if (response) {
            waysToWearContainer.innerHTML = response;
        } else {
            waysToWearContainer.innerHTML = '';
        }
        $body.trigger('search:updateProducts');
    }).always(function () {
        $(waysToWearContainer).spinner().stop();
        isContentModulesFetched = true;
    });

    destroyContentModulesIntersectionObserver(waysToWearContainer);
}

/**
 * Fetch Ways to Wear components only when user scroll near their position
 * @param {string} groupId - selected variation group id
 * @param {string} variationUrl - url to fetch the content
 */
function dynamicallyFetchContentModules(groupId, variationUrl) {
    const waysToWearContainer = queryFirst('.ways-to-wear');
    if (!waysToWearContainer) { return; }

    observer = new IntersectionObserver(
        ([entry]) => updateContentModules(groupId, variationUrl, waysToWearContainer, entry),
        { rootMargin: '500px', threshold: 0 }
    );
    observer.observe(waysToWearContainer);
}

/**
 * Destroy Intersection Observer of Ways to Wear components since it needed only once
 * @param {HTMLElement} waysToWearContainer - container with recommendations
 */
function destroyContentModulesIntersectionObserver(waysToWearContainer) {
    if (observer instanceof IntersectionObserver) {
        observer.unobserve(waysToWearContainer);
        observer = null;
    }
}

/**
 * updates image urls and presets by appending the scene7 url
 * @param {Object} images - images array to be updated
 * @returns {Object} - updated images array
 */
function updateImageDetails(images) {
    const updatedImages = images.map(imageObj => {
        const srcsetPresets = JSON.parse(imageObj.srcset);
        let srcsetString = [];
        srcsetPresets.forEach(function (item) {
            srcsetString.push(imageObj.imageScene7 + '?' + item.preset + ' ' + item.breakpoint);
        });
        return {
            alt: imageObj.alt,
            url: imageObj.imageScene7 + imageObj.url,
            srcset: srcsetString.join(', '),
            title: imageObj.title
        };
    });
    return updatedImages;
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Object} currentElement - selected color element
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateProductDetails(currentElement, $productContainer) {
    const { promotionMessageTmpl } = require('../templates').productDetail;
    const { initPopoverPromotions, initPopoverClose } = require('../popover');
    const elementData = currentElement.dataset;
    const variantGroupId = elementData.attrValue;
    const productContainer = $productContainer[0];
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const swatchContainer = queryFirst('.color-container', productContainer);
    const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
    const assistiveTextElement = queryFirst('.selected-assistive-text', currentElement.closest('.color-list'));
    const monogramBtn = queryFirst('.monogram-btn', productContainer);
    const masterId = productContainer.dataset.masterid;
    const productInfo = window.productInfo[masterId];
    const { groupId, sizes, images, shopThePrint, price, standardPrice, videoUrl, variantsList, mgFlag, mgLocs, hasWaysToWear, isFinalSale, isDirectlyPurchasable, earlyAccess, promotions } = productInfo.variants[variantGroupId];
    productContainer.dataset.wishlistId = groupId;
    setWishlistProductId(groupId, productContainer);
    const colorElements = queryAll('.color-attribute .swatch-circle', productContainer);
    const { attrDisplayvalue, customPageTitle, pageTitle, includeLillyColor, lillyColorName, customPageDescription, pageDescription } = elementData;
    const titleElement = queryFirst('.meta-page-title');
    const descriptionElement = queryFirst('.meta-page-description');
    const waysToWearEl = queryFirst('.ways-to-wear', productContainer);
    const variationGridUrl = waysToWearEl.dataset.variationGridUrl;
    const earlyAccessBadgeTextEl = queryFirst('.loyalty-product-pdp-badge .product-tile-badge-text', productContainer);
    const earlyAccessText = earlyAccessBadgeTextEl.textContent;
    // update Marketing Analytics trackWishlist event
    let marketingCloudPageAnalytics = [{ item: groupId }];
    trackPageView(marketingCloudPageAnalytics);
    // Updated logic here
    if (swatchNameElement) {
        const selectedSwatchElement = queryFirst('.selected-swatch', productContainer);
        const colorlabel = selectedSwatchElement.dataset.colorLabel;
        swatchNameElement.textContent = colorlabel + attrDisplayvalue;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, attrDisplayvalue);
        queryFirst('.selected-swatch', productContainer).innerHTML = swatchHtml;
    }

    if (customPageTitle && titleElement) {
        titleElement.textContent = customPageTitle;
    } else if (pageTitle && titleElement) {
        titleElement.textContent = pageTitle;
    }
    const longDescriptionElement = queryFirst('.long-description-container .long-description', productContainer);
    if (longDescriptionElement) {
        const { longDescription } = longDescriptionElement.dataset;
        let description;
        if (includeLillyColor !== 'false' && lillyColorName) {
            description = tokenize(longDescription, { lillyColor: lillyColorName }, '{{', '}}');
        } else {
            description = tokenize(longDescription, { lillyColor: '' }, '<li>{{', '}}</li>');
        }
        longDescriptionElement.innerHTML = description;
    }
    if (customPageDescription && descriptionElement) {
        descriptionElement.content = customPageDescription;
    } else if (pageDescription && descriptionElement) {
        descriptionElement.content = pageDescription;
    }

    removeClass(colorElements, 'selected');
    addClass(queryFirst('.swatch-circle', currentElement), 'selected');
    queryFirst('.product-id', productContainer).textContent = masterId;

    assistiveElements.forEach(eachElement => {
        eachElement.textContent = '';
    });

    assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;

    const productFinalSaleMessage = queryFirst('.on-sale-message', productContainer);
    const productFreeReturnsMessage = queryFirst('.pdp-header-promo');
    if (productFinalSaleMessage) {
        if (isFinalSale) {
            removeClass(productFinalSaleMessage, hiddenClass);
            addClass(productFreeReturnsMessage, 'd-none');
        } else {
            addClass([productFinalSaleMessage], hiddenClass);
            removeClass(productFreeReturnsMessage, 'd-none');
        }
    }

    if (mgFlag && mgLocs && mgLocs.length > 0) {
        removeClass(monogramBtn, hiddenClass);
    } else {
        addClass(monogramBtn, hiddenClass);
    }

    if (hasWaysToWear) {
        isContentModulesFetched = false;
        dynamicallyFetchContentModules(groupId, variationGridUrl);
    } else {
        waysToWearEl.innerHTML = '';
    }
    updateSizeElements(sizes, productContainer, variantsList);

    $body.trigger('product:updateStoreInventory', {
        productContainer
    });
    if (!isDirectlyPurchasable) {
        const sizeElements = queryAll('.size-btn', productContainer);
        addClass(sizeElements, notAvailable);
    }
    updateShopThePrint(shopThePrint, productContainer);
    updateProductPrice(price, standardPrice, productContainer);
    const ssColor = currentElement.dataset.attrDisplayvalue;
    const ssSizeElement = queryFirst('.size-btn.selected', productContainer);
    const ssSize = ssSizeElement ? ssSizeElement.dataset.attrValue : 'unknown';

    if (isFitPredictorEnabled) {
        updateProductData(ssColor, ssSize, standardPrice, price, productContainer);
        handleColor(productContainer);
    }

    if (!ssSizeElement) {
        $('.availability-msg', $productContainer).empty();
        // update promotion messages display for selected color (size not selected yet here)
        queryFirst('.promotions').innerHTML = promotions ? promotions.filter((promotion) => promotion.calloutMsg).map((promotion, promotionIdx) => {
            return promotionMessageTmpl(promotion.calloutMsg, promotion.details, promotionIdx);
        }).join('') : '';
        // pick new promotion popover elements
        initPopoverPromotions();
        initPopoverClose();
    }
    // Update primary images
    var primaryImageUrls = updateImageDetails(images.large);

    if (window.matchMedia('(max-width: 767.98px)').matches) {
        const carouselContainerEl = queryFirst('.carousel.image-slider', productContainer);
        const carouselContainerHeight = carouselContainerEl.offsetHeight;
        carouselContainerEl.style.minHeight = carouselContainerHeight + 'px';
    }
    const isEarlyAccessItem = isEarlyAccessElement(earlyAccess);
    createCarousel(primaryImageUrls, $productContainer, isEarlyAccessItem, earlyAccessText, videoUrl);
    $body.trigger('product:afterAttributeChange', {
        container: $productContainer,
        variantGroupId,
        groupId
    });
    $body.trigger('product:handleImageZoom');
    const wishlistButton = queryFirst('.add-to-wish-list', productContainer);
    if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
        removeClass(wishlistButton, 'added-to-wish-list');
        wishlistButton.disabled = false;
        const assistiveText = wishlistButton.getAttribute('data-assistive-text');
        wishlistButton.setAttribute('aria-label', assistiveText);
    }
    // Updated logic here
    const swatchParam = `?dwvar_${masterId}_color`;
    const queryParams = location.search;
    let UpdatedParams = `${swatchParam}=${variantGroupId}`;
    if (queryParams) {
        UpdatedParams = queryParams
            .split('&')
            .map(eachParam => {
                if (eachParam.indexOf(swatchParam) > -1) {
                    return `${swatchParam}=${variantGroupId}`;
                }
                return eachParam;
            })
            .join('&');
    }
    history.replaceState({ variantGroupId }, document.title, UpdatedParams);
}

/**
 * updates the quick view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Object} currentElement - selected color element
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateQuickViewDetails(currentElement, $productContainer) {
    const elementData = currentElement.dataset;
    const variantGroupId = elementData.attrValue;
    const productContainer = $productContainer[0];
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const swatchContainer = queryFirst('.color-container', productContainer);
    const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
    const assistiveTextElement = queryFirst('.selected-assistive-text', currentElement.closest('.color-list'));
    const quickViewInfo = window.quickviewProductInfo;
    const { productInfo } = quickViewInfo;
    const { groupId, sizes, images, price, standardPrice, variantsList, isDirectlyPurchasable, pdpBreadCrumbs, earlyAccess } = productInfo.variants[variantGroupId];
    productContainer.dataset.wishlistId = groupId;
    setWishlistProductId(groupId, productContainer);
    handleEarlyAccessCta(productContainer, (earlyAccess && earlyAccess.isEarlyAccessProduct));
    const colorElements = queryAll('.color-attribute .swatch-circle', productContainer);
    const { attrDisplayvalue } = elementData;
    // Updated logic here
    if (swatchNameElement) {
        swatchNameElement.textContent = attrDisplayvalue;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, attrDisplayvalue);
        queryFirst('.selected-swatch', productContainer).innerHTML = swatchHtml;
    }
    removeClass(colorElements, 'selected');
    addClass(queryFirst('.swatch-circle', currentElement), 'selected');

    assistiveElements.forEach(eachElement => {
        eachElement.textContent = '';
    });

    assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;

    updateSizeElements(sizes, productContainer, variantsList);
    if (!isDirectlyPurchasable) {
        const sizeElements = queryAll('.size-btn', productContainer);
        addClass(sizeElements, notAvailable);
    }

    updateProductPrice(price, standardPrice, productContainer);
    const ssColor = currentElement.dataset.attrDisplayvalue;
    const ssSizeElement = queryFirst('.size-btn.selected', productContainer);
    const ssSize = ssSizeElement ? ssSizeElement.dataset.attrValue : 'unknown';

    if (isFitPredictorEnabled) {
        updateProductData(ssColor, ssSize, standardPrice, price, productContainer);
        handleColor(productContainer);
    }

    if (!ssSizeElement) {
        $('.availability-msg', $productContainer).empty();
    }
    // Update primary images
    var primaryImageUrls = updateImageDetails(images.large);
    if (window.matchMedia('(min-width: 1024px)').matches) {
        const carouselContainerEl = queryFirst('.carousel', productContainer);
        const carouselContainerHeight = carouselContainerEl.offsetHeight;
        carouselContainerEl.style.minHeight = carouselContainerHeight + 'px';
    }
    const isEarlyAccessItem = isEarlyAccessElement(earlyAccess);
    createCarousel(primaryImageUrls, $productContainer, isEarlyAccessItem);
    $body.trigger('product:quickViewAttributeChange', {
        container: $productContainer,
        variantGroupId,
        groupId,
        productContainer,
        monetateData: {
            pdpBreadCrumbs
        }
    });
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.bonus-acordion-container');
    var selectedProducts = $html.find('.selected-products-container');
    var footer = $html.find('.modal-footer');

    return {
        body,
        footer,
        selectedProducts
    };
}

/**
 * Function to update GWP elements on swatch or size change
 */
function updateGwpElements() {
    $('.choice-of-bonus-product').on('bonus:afterAttributeSelect', function () {
        const productDialog = queryFirst('.choose-bonus-product-dialog');
        const accordionContainer = this.closest('.bonus-acordion-container', productDialog);
        const bonusAccordionElements = queryAll('.bonus-acordion-container', productDialog);
        const addToCart = queryFirst('.add-bonus-products', productDialog);
        const sizeEl = queryFirst('.select-size', this);
        const isSizeSelected = sizeEl ? !!sizeEl.selectedIndex : true;
        const selectedSwatch = queryFirst('.color-attribute .swatch-circle.selected', this);
        const isSelectedSizeUnavailable = sizeEl && hasClass($(sizeEl).children('option:selected')[0], notAvailable);

        if (sizeEl) {
            const firstOption = queryFirst('option', sizeEl);

            // Force this option's resetUrl to remove the size
            firstOption.value = firstOption.value.replace(/(_size=)[^&]+/, '$1');
        }

        if (isSelectedSizeUnavailable) {
            addClass(sizeEl, notAvailable);
        } else {
            removeClass(sizeEl, notAvailable);
        }

        let isEnabled = true;
        const maxItems = parseInt(accordionContainer.dataset.maxItems, 10);
        const selectedItemsCount = queryAll('.select-bonus-product:checked', accordionContainer).length;

        if (selectedItemsCount < maxItems) {
            isEnabled = false;
        }

        if (isEnabled && isSizeSelected && !!selectedSwatch && !isSelectedSizeUnavailable) {
            addClass(accordionContainer, readyClass);
        } else {
            isEnabled = false;
            removeClass(accordionContainer, readyClass);
        }

        const validProducts = queryAll('.bonus-acordion-container.ready-to-add', productDialog);

        addToCart.disabled = !isEnabled || hasClass(addToCart, notAvailable) || bonusAccordionElements.length !== validProducts.length;
    });
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const { maxBonusItems, addToCartUrl, uuid, pliUUID, pageSize, showProductsUrlRuleBased, bonusChoiceRuleBased, bonusDiscountLineItems } = data;
    $('.choose-bonus-product-dialog').attr({
        'data-total-qty': maxBonusItems,
        'data-addToCartUrl': addToCartUrl,
        'data-UUID': uuid,
        'data-pliUUID': pliUUID,
        'data-pageStart': 0,
        'data-pageSize': pageSize,
        'data-moreURL': showProductsUrlRuleBased,
        'data-bonusChoiceRuleBased': bonusChoiceRuleBased,
        'data-bonus-items': JSON.stringify(bonusDiscountLineItems)
    });

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $gwpDialog.find('.enter-message').text(response.enterDialogMessage);
            $gwpDialog.find('.modal-body').html(parsedHtml.body);
            $gwpDialog.find('.modal-footer, .selected-products-container').remove();
            $gwpDialog.find('.modal-content').append(parsedHtml.footer);
            $(parsedHtml.selectedProducts).insertAfter($gwpDialog.find('.modal-header'));
            // LP custom changes start
            const { selectedBonusProducts } = response;
            if ((selectedBonusProducts || []).length) {
                const modalDialog = queryFirst('.choose-bonus-product-dialog');
                let selectedProductsCount = 0;
                selectedBonusProducts.forEach(eachProductList => {
                    if (eachProductList.length) {
                        const bonusAccordionContainer = queryFirst(`.bonus-acordion-container[data-uuid="${eachProductList[0].uuid}"]`, modalDialog);
                        const maxCount = parseInt(bonusAccordionContainer.dataset.maxItems, 10);
                        const productListLength = eachProductList.length;
                        if (maxCount === productListLength) {
                            addClass(bonusAccordionContainer, 'selected');
                            addClass(bonusAccordionContainer, readyClass);
                        }
                        const bonusItemCount = queryFirst('.bonus-item-count span', bonusAccordionContainer);
                        bonusItemCount.textContent = productListLength;
                        selectedProductsCount += productListLength;
                        eachProductList.forEach(eachProduct => {
                            const selectedProduct = queryFirst(`.choice-of-bonus-product[data-pid="${eachProduct.pid}"][data-uuid="${eachProduct.uuid}"]`);
                            const selectBonusCheckbox = queryFirst('.select-bonus-product', selectedProduct);

                            if (selectBonusCheckbox) {
                                selectBonusCheckbox.checked = true;
                            }

                            addClass(selectedProduct, selectedProductClass);
                            setControlsEnabled(selectedProduct, true);
                        });
                    }
                });
                const addToCart = queryFirst('.add-bonus-products', modalDialog);
                addToCart.disabled = maxBonusItems !== selectedProductsCount;
            }

            // Default all size dropdowns that aren't selected products
            $('.bonus-product-item:not(.selected-product) .select-size').each(function () {
                this.selectedIndex = 0;
            });

            updateGwpElements();
            // LP custom changes end
            $gwpDialog.modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    if (!response.error) {
        return;
    }

    if (response.displayModal) {
        let addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');

        if (!addToCartWarningDialog) {
            const isOnCartPage = (window.location.pathname === '/cart/');
            const fragment = renderFragment(dialogTemplate({
                buttons: isOnCartPage ? [{ text: 'OK', primary: true }] : [{ text: 'Close' }, { text: 'Review Tote', primary: true, link: response.redirectLink }],
                modalContentHeading: response.messageHeading,
                modalContentBody: isOnCartPage ? response.messageBodyOnCart : response.messageBodyOffCart,
                id: 'add-to-cart-warning-dialog'
            }));

            document.body.appendChild(fragment);
            addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');
        }

        $(addToCartWarningDialog).modal('show');
    } else {
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        $('.add-to-cart-messages').remove();
        if ($('.add-to-cart-messages').length === 0) {
            $body.append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append('<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' + response.message + '</div>');

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        })
        .toArray();

    return JSON.stringify(options);
}

/**
 * Enables or disables the color/size controls in a container
 * @param {HTMLElement} container - The container element
 * @param {boolean} enable - Whether to enable the controls (false to disable)
 */
function setControlsEnabled(container, enable) {
    const controls = queryAll('button:not(.select-add):not(.select-remove), select', container);
    const blocker = queryAll('.bonus-product-blocker', container);
    const blockerActiveClass = 'active';

    controls.forEach(control => {
        control.disabled = !enable;
    });

    if (enable) {
        blocker.forEach(blockElement => {
            const sizeElement = queryFirst('select', blockElement.closest('.product-detail'));

            if (sizeElement) {
                $(sizeElement).trigger('change');
            }
        });

        removeClass(blocker, blockerActiveClass);
    } else {
        addClass(blocker, blockerActiveClass);
    }
}

/**
 * Get analytics data to be associated with a product line item when adding to cart
 * @returns {Object} analytics data
 */
function getAddToCartAnalyticsData() {
    const promoName = getStoredDataLayerInfo(P_PROMO_NAME_KEY);
    const promoCreativeName = getStoredDataLayerInfo(P_PROMO_CREATIVE_NAME);
    const listName = getStoredDataLayerInfo(P_PRODUCT_LIST_KEY);
    const data = {};

    if (promoName && Array.isArray(promoName)) data.utagPromoName = promoName[0]; // promo_name should always be an array, but we don't want to store it that way on the BE
    if (promoCreativeName && Array.isArray(promoCreativeName)) data.utagPromoCreativeName = promoCreativeName[0];
    if (listName) data.utagListName = listName;

    return data;
}

export default {
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },
    attributeSelect,
    updateProductDetails,
    updateImageDetails,
    focusChooseBonusProductModal: function () {
        $gwpDialog.on('shown.bs.modal', () => {
            $gwpDialog.siblings().attr('aria-hidden', 'true');
            $gwpDialog.find('.close').trigger('focus');
        });
    },

    onClosingChooseBonusProductModal: function () {
        $gwpDialog.on('hidden.bs.modal', () => {
            $gwpDialog.siblings().attr('aria-hidden', 'false');
            removeClass(queryFirst('.choose-bonus-product-dialog'), 'selected');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $body.on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button.color-attribute', function (e) {
            e.preventDefault();
            const productDetailContainer = queryFirst(PRODUCT_DETAIL_CONTAINER_SELECTOR);

            if ($(this).attr('disabled') || hasClass(queryFirst('.swatch-circle', this), 'selected')) {
                return;
            }
            var $productContainer = $(this).closest('.set-items .product-detail, .choose-bonus-product-modal .product-detail, .gift-card-main, .product-quickview');
            if ($productContainer.hasClass('cached-quick-view')) {
                updateQuickViewDetails(this, $productContainer);
            } else if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
                updateProductDetails(this, $productContainer);
            } else {
                selectColorAttribute($(this).attr('data-url'), $productContainer);
            }
            $body.trigger('swatchChangeEvent', this);
            $body.trigger('search:updateProducts');
            updateVisibilityOfLowInventoryMsg(productDetailContainer);
        });
    },

    renderSizeElements: function () {
        const swatchEl = queryFirst('.pdp-container:not(.gift-card-main) button.color-attribute .swatch-circle.selected');

        if (swatchEl) {
            const productContainer = queryFirst('.pdp-container');
            const selectedSizeEl = queryFirst('.size-btn.selected', productContainer);

            if (!selectedSizeEl) {
                const selectedSwtachBtn = swatchEl.parentElement;
                const variantGroupId = selectedSwtachBtn.dataset.attrValue;
                const masterId = productContainer.dataset.masterid;
                const productInfo = window.productInfo[masterId];
                const { sizes, variantsList, isDirectlyPurchasable, wishlistDisabled, isFinalSale } = productInfo.variants[variantGroupId];

                updateSizeElements(sizes, productContainer, variantsList);
                if (!isDirectlyPurchasable) {
                    const sizeElements = queryAll('.size-btn', productContainer);
                    addClass(sizeElements, notAvailable);
                }
                if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
                    showStrikeHearts(productContainer);
                }
            }

            $body.trigger('product:updateStoreInventory', {
                productContainer: productContainer
            });
        }
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            if (!e.target.closest('.fp-root')) {
                e.preventDefault();
                var $productContainer = $(this).closest('.set-item');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.product-detail');
                }
                attributeSelect(e.currentTarget.value, $productContainer);
            }
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
            }
        });
    },

    addToCart: () => {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', async e => {
            // Elements
            const { target } = e;
            const $target = $(target);
            const productContainer = target.closest('.product-detail');
            const $productContainer = $(productContainer);
            const storeLocatorContainer = queryFirst('.store-locator-container');
            const ispuAddToCart = queryFirst('.add-to-cart', storeLocatorContainer);

            if (ispuAddToCart) {
                ispuAddToCart.disabled = true;
            }

            const pdpSetData = {};
            let isPDPSet = false;

            // PDP Set - add all to cart button
            if (hasClass(target, 'add-to-cart-global')) {
                isPDPSet = true;
                const setPids = [];

                const setModal = target.closest('.custom-set-detail-modal');
                // all visible set products -- "removed" set products are not included (removed set products do not have the custom-set-product class)
                const modalProducts = queryAll('.custom-set-items.set-items .custom-set-product', setModal);

                // set products which have a selected size
                const sizeSelectedProducts = modalProducts.filter(modalProduct => {
                    toggleSelectSizeInfo(modalProduct);
                    const sizeSelected = queryFirst('.size-btn.selected', modalProduct);

                    if (sizeSelected) {
                        // collect some data for the ATC call while we're looping anyway
                        setPids.push({
                            pid: queryFirst('.product-id', modalProduct)?.textContent,
                            options: getOptions($(modalProduct))
                        });
                    }

                    return !!sizeSelected;
                });

                // exit if not all sizes are selected
                if (sizeSelectedProducts.length !== modalProducts.length) {
                    return;
                }

                Object.assign(pdpSetData, {
                    pidsObj: JSON.stringify(setPids)
                });
            } else {
                // Not PDP Set -- Check if size is selected on single product PDP
                toggleSelectSizeInfo(productContainer);
                if (!queryFirst('.size-btn.selected', productContainer)) {
                    return;
                }
            }

            $body.trigger('product:beforeAddToCart', target);

            const analyticsData = getAddToCartAnalyticsData();

            const form = {
                pid: getPidValue($target),
                childProducts: getChildProducts(),
                ...pdpSetData,
                ...analyticsData
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $target.trigger('updateAddToCartFormData', form);

            const addToCartUrl = getAddToCartUrl();
            if (addToCartUrl) {
                try {
                    const res = await fetch(addToCartUrl, {
                        method: 'POST',
                        body: toFormData(form)
                    });

                    if (!res.ok) throw new Error('Add to cart response was not OK');

                    const resData = await res.json();

                    if (isPDPSet) {
                        $('#productSetModal').modal('hide');
                    }

                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                        $productContainer.find('.lp-slideout-modal[id*="pick-up-in-store"]').modal('hide');
                    }

                    handlePostCartAdd(resData);

                    $.spinner().stop();
                    $body.trigger('product:afterAddToCart', resData);

                    // send analytics data (trackAddItemToCart) if it is Stylitics Gallery QuickView on PLP
                    // and Stylitics Gallery Widget exists
                    if (hasClass(productContainer, 'is-stylitics-gallery')) {
                        $body.trigger('stylitics:gallery:addItem', {
                            pid: form.pid,
                            price: queryFirst('.price-section .sales .ssSalesPrice', productContainer)?.value
                        });
                    }
                } catch (err) {
                    $.spinner().stop();
                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                    }
                    console.error('addToCart error:', err.message);
                }
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $choiceOfBonusProduct.data('pid');
            var uuid = $choiceOfBonusProduct.data('uuid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = 1;
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });

            // LP customization changes start
            const { checked } = this;
            const productDialog = queryFirst('.choose-bonus-product-dialog');
            const choiceOfBonusProduct = $choiceOfBonusProduct[0];
            const addToCart = queryFirst('.add-bonus-products', productDialog);
            const selectedProductElement = queryFirst(`.selected-pid[data-pid="${pid}"][data-uuid="${uuid}"]`, productDialog);
            let bonusAccordionElements = queryAll('.bonus-acordion-container', productDialog);
            let accordionContainer = this.closest('.bonus-acordion-container', productDialog);
            const bonusCountElement = queryFirst('.bonus-item-count span', accordionContainer);
            const selectedCount = queryAll('.select-bonus-product:checked', accordionContainer).length;
            const maxCount = parseInt(accordionContainer.dataset.maxItems, 10);
            let sizeEl = queryFirst('.select-size', choiceOfBonusProduct);
            let selectedSwatch = queryFirst('.color-attribute .swatch-circle.selected', choiceOfBonusProduct);
            let isSizeSelected = sizeEl ? !!sizeEl.selectedIndex : true;

            if (selectedCount < maxCount) {
                removeClass(accordionContainer, 'selected');
            } else {
                addClass(accordionContainer, 'selected');
            }
            bonusCountElement.textContent = selectedCount;

            let enableAddTocart = true;
            const maxItems = parseInt(accordionContainer.dataset.maxItems, 10);
            const selectedItemsCount = queryAll('.select-bonus-product:checked', accordionContainer).length;
            if (selectedItemsCount < maxItems) {
                enableAddTocart = false;
            }
            enableAddTocart = isSizeSelected && !!selectedSwatch && enableAddTocart;

            if (enableAddTocart) {
                addClass(accordionContainer, readyClass);
            } else {
                removeClass(accordionContainer, readyClass);
            }

            let validProducts = queryAll('.bonus-acordion-container.ready-to-add', productDialog);

            if (!hasClass(addToCart, notAvailable)) {
                addToCart.disabled = bonusAccordionElements.length !== validProducts.length;
            }

            if (checked) {
                addClass(choiceOfBonusProduct, selectedProductClass);
            } else {
                removeClass(choiceOfBonusProduct, selectedProductClass);
                if (selectedProductElement) {
                    selectedProductElement.click();
                }
            }
            if (selectedCount < maxCount) {
                $(accordionContainer).find('.choice-of-bonus-product').find('.select-bonus-product, .color-attribute, select').removeAttr('tabindex');
            } else {
                $(accordionContainer).find('.choice-of-bonus-product:not(.selected-product)').find('.select-bonus-product, .color-attribute, select').attr('tabindex', -1);
            }
            if (!checked) {
                return;
            }
            // LP customization changes end

            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml =
                    '' +
                    '<div class="selected-pid row" ' +
                    'data-pid="' +
                    pid +
                    '"' +
                    'data-uuid="' +
                    uuid +
                    '"' +
                    'data-qty="' +
                    submittedQty +
                    '"' +
                    'data-optionID="' +
                    (optionID || '') +
                    '"' +
                    'data-option-selected-value="' +
                    (valueId || '') +
                    '"' +
                    '>' +
                    '<div class="col-sm-11 col-9 bonus-product-name" >' +
                    $choiceOfBonusProduct.find('.product-name').html() +
                    '</div>' +
                    '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>' +
                    '</div>';
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });

        const bonusDrawer = document.getElementById('chooseBonusProductModal');

        if (bonusDrawer) {
            bonusDrawer.addEventListener('click', e => {
                const { target } = e;
                if (hasClass(target, 'bonus-product-blocker') || hasClass(target, 'select-add') || hasClass(target, 'select-remove')) {
                    e.preventDefault();
                    const bonusTile = target.closest('.choice-of-bonus-product-tile');
                    if (bonusTile) {
                        const targetCheckbox = queryFirst('.select-bonus-product', bonusTile);

                        if (!targetCheckbox.checked) {
                            // unselect the checked checkbox as long as the user didn't click the already-checked option
                            const accordion = bonusTile.closest('.bonus-acordion-container');
                            const checkedBox = queryFirst('input:checked', accordion);
                            if (checkedBox) {
                                checkedBox.click();
                            }
                        }

                        // toggle the checkbox for the clicked tile
                        targetCheckbox.click();
                    }
                }
            });
        }
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $body.on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled', !response.product.readyToOrder || !response.product.available);
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });

        const bonusProductDialog = queryFirst('.choose-bonus-product-dialog');

        if (bonusProductDialog) {
            bonusProductDialog.addEventListener('click', e => {
                const target = e.target.closest('.select-bonus-product');

                if (!target) return;

                setControlsEnabled(target.closest('.row'), target.checked);
            });
        }
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this).data('qty'), 10);
                const pid = $(this).data('pid');
                const uuid = $(this).data('uuid');
                const productId = $(`.choice-of-bonus-product.selected-product[data-pid="${pid}"][data-uuid="${uuid}"] .product-detail`).attr('data-pid');
                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = productId;
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        uuid: uuid,
                        pid: productId,
                        qty: qtyOption,
                        options: option ? [option] : []
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&bonusItems=' + JSON.stringify($('.choose-bonus-product-dialog').data('bonusItems'));
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $body.append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append('<div class="alert alert-danger add-to-basket-alert text-center" role="alert">' + data.errorMessage + '</div>');
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');
                        $('.minicart-quantity').html(data.totalQty);
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    revealRecommendations: function () {
        const { initSpecificCarousel } = require('../components/carousel');
        queryAll('.recommendations:not(.product-listing-header)').forEach(eachRecommendation => {
            const titleEl = queryFirst('.title', eachRecommendation);
            const productEl = queryFirst('.grid-tile', eachRecommendation);
            const scrollableContent = queryFirst('.scrollable-content', eachRecommendation);

            if (titleEl && !productEl) {
                eachRecommendation.outerHTML = '';
            } else if (titleEl && productEl) {
                eachRecommendation.style.display = 'block';
                if (scrollableContent) initSpecificCarousel(scrollableContent);
            }
        });
    },
    handleEarlyAccessPLPLockIcon: function () {
        const earlyAccessPLPContainer = queryAll('.early-access-plp-container');
        if (earlyAccessPLPContainer.length) {
            earlyAccessPLPContainer.forEach(earlyAccessPlpIcon => {
                const lockIconContainer = queryFirst('.loyalty-early-access-lock-container', earlyAccessPlpIcon);
                const earlyAccessWishlistIcon = queryFirst('.product-tile .add-to-wish-list-container', earlyAccessPlpIcon);
                const earlyAccessPLPBadge = queryFirst('.loyalty-product-tile-badge', earlyAccessPlpIcon);
                const { earlyAccessDate } = lockIconContainer.dataset;
                const isEarlyAccessItem = isEarlyAccessElement(earlyAccessDate);
                if (isLoyaltyProgramMember || !isEarlyAccessItem) {
                    removeClass(earlyAccessWishlistIcon, HIDDEN_CLASS);
                    addClass(lockIconContainer, HIDDEN_CLASS);
                    if (!isEarlyAccessItem) {
                        addClass(earlyAccessPLPBadge, HIDDEN_CLASS);
                    }
                } else {
                    addClass(earlyAccessWishlistIcon, HIDDEN_CLASS);
                    removeClass([lockIconContainer, earlyAccessPLPBadge], HIDDEN_CLASS);
                }
            });
        }
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    handleEarlyAccessCta: handleEarlyAccessCta
};
