'use strict';

import { queryFirst, addClass, removeClass } from '../domUtil';
import { getJSON } from '../util';

const $body = $('body');
const hiddenClass = 'd-none';

export default {
    validateEmail: function () {
        $body.on('blur', '.notify-email', e => {
            const { target } = e;
            const { value } = target;
            const emailFormGroup = target.closest('.form-group');
            const emailError = queryFirst('.email-error', emailFormGroup);
            const { missingError, patternMismatch } = target.dataset;
            const invalidClass = 'is-invalid';
            if (!value) {
                addClass(target, invalidClass);
                emailError.textContent = missingError;
            } else if (target.checkValidity && !target.checkValidity()) {
                addClass(target, invalidClass);
                emailError.textContent = patternMismatch;
            } else {
                removeClass(target, invalidClass);
            }
        });
    },
    notifyMeSubmit: function () {
        $body.on('submit', '.js-notify-form', e => {
            e.preventDefault();
            const { target } = e;
            const actionUrl = target.getAttribute('action');
            const data = {
                productID: queryFirst('#notifySku').value,
                email: queryFirst('.notify-email').value,
                subscribe: document.getElementById('notify-checkbox').checked
            };
            const $notifyMe = $('#notifyMe');
            $notifyMe.spinner().start();
            getJSON(actionUrl, 'POST', data, (data) => {
                const confirmMessage = data.message;
                addClass(queryFirst('.notify-me-container .js-notify-me-desc'), hiddenClass);
                addClass(queryFirst('.js-notify-form'), hiddenClass);
                const successMessage = queryFirst('.js-notify-confirm-msg');
                successMessage.innerHTML = confirmMessage;
                removeClass(queryFirst('.js-notify-confirm-block'), hiddenClass);
                removeClass(queryFirst('.footer-close-link'), hiddenClass);
                $notifyMe.spinner().stop();
            }, () => {
                $notifyMe.spinner().stop();
            });
        });
    },
    notifyMeContainerSubmit: function () {
        $body.on('submit', '.js-notify-form-inline', e => {
            e.preventDefault();
            const { target } = e;
            const actionUrl = target.getAttribute('action');
            const data = {
                productID: queryFirst('#notifySku', target).value,
                email: queryFirst('#emailInput', target).value,
                subscribe: queryFirst('#notify-checkbox', target).checked
            };
            const $container = $('.js-notify-me-container');
            $container.spinner().start();
            getJSON(actionUrl, 'POST', data, (data) => {
                $container.spinner().stop();
                const notifyMeContainer = queryFirst('.js-notify-me-container');
                const notifyConfirm = queryFirst('.js-notify-confirm-msg', notifyMeContainer);
                if (data.message && notifyConfirm) notifyConfirm.innerHTML = data.message;

                if (data.sucess !== 'error') {
                    const productName = notifyMeContainer.getAttribute('data-nm-p-name');
                    const productColor = queryFirst('.selected-swatch-name').textContent;
                    const productSize = queryFirst('.size-accordion .details-text').textContent;

                    const pNameEl = queryFirst('.js-notifyme-name', notifyMeContainer);
                    pNameEl.textContent = productName;

                    if (productColor) {
                        const pColorEl = queryFirst('.js-notifyme-color', notifyMeContainer);
                        pColorEl.textContent = productColor;
                    }
                    if (productSize) {
                        const pSizeEl = queryFirst('.js-notifyme-size', notifyMeContainer);
                        pSizeEl.textContent = productSize;
                    }
                }

                addClass(queryFirst('.js-notify-form-inline', notifyMeContainer), hiddenClass);
                removeClass(queryFirst('.js-confirmed-block', notifyMeContainer), hiddenClass);
                queryFirst('.js-close-notifyme').focus();
            }, () => {
                $container.spinner().stop();
            });
        });
    },
    closeNotifyMeForm: function () {
        $body.on('click', '.js-close-notifyme', e => {
            e.preventDefault();
            addClass(queryFirst('.js-confirmed-block'), hiddenClass);
            removeClass(queryFirst('.js-notify-form-inline'), hiddenClass);
        });
    },
    preSelectNotifyCheckbox: function () {
        $body.on('shown.bs.modal', '#notifyMe', function (e) {
            // pre-select notifyMe checkbox after modal is opened
            const notifyMeId = queryFirst('#notify-checkbox', e.currentTarget);
            if (notifyMeId && !notifyMeId.checked) notifyMeId.checked = true;
        });
    }
};
