'use strict';

/**
 * Helper module for initialization popover elements
 *
 * @module popover
 */

const { queryFirst, queryAll, hasClass } = require('./domUtil');

const phInfoPopover = queryFirst('.ph-number-info-popover .content-asset');
const fpoInfoPopover = queryFirst('.fpo-info-popover .content-asset');
const checkoutBenefitsInfoPopover = queryFirst('.checkout-benefits-info .checkout-benefits-info-content');
const cvvInfoPopover = queryFirst('.cvv-info-popover .cvv-info-popover-content');
const giftCardInfoPopover = queryFirst('.gift-card-number-info-popover .content-asset');
const birthdayInfoPopover = queryFirst('.birthday-info-popover .content-asset');
const deliveryFeeInfoPopover = queryFirst('.delivery-fee-info-popover .content-asset');
const boltSignoutMessage = queryFirst('.bolt-logout-button .bolt-sign-out');
const earlyAccessMessage = queryFirst('.loyalty-early-access-lock-container .early-access-container-tooltip');
const giftReturnNumberInfoPopover = queryFirst('.gift-return-order-no-popover .content-asset');
const keepUserSignedInPopover = queryFirst('.remember-me .keep-user-signed-in');
const keepUserSignedInContainer = queryFirst('.remember-me .logged-in-user-notification');
const earlyAccessLockedSelector = '.loyalty-early-access-locked';
const pdpChatWrapper = queryFirst('.pdp-chat');
const pdpChatPopover = queryFirst('.pdp-chat-label');
const pdpChatContainer = queryFirst('.pdp-chat-container');

const initPopover = (selector, ...options) => $(selector).popover(Object.assign({}, ...options));

// default popover options
const POPOVER_CLICK_BOTTOM = {
    trigger: 'click',
    placement: 'bottom',
    html: true
};
/*
 * Handle clicks outside of popover in order to close it
 */
const initPopoverClose = () => {
    // since it can be called dynamically, first we get rid of old events
    $('[data-toggle="popover"]')
        .off('shown.bs.popover')
        .on('shown.bs.popover', function (e) {
            const { target } = e;
            $(document).one('click', function (clickEvent) {
                const clicked = clickEvent.target;
                if (clicked !== target && clicked.parentElement !== target && $('.popover.show').length) {
                    $(target).trigger('click');
                }
            });
        });
};
/*
 * Assign custom close event on custom selector
 */
const initPopoverCloseSelector = () => {
    $('[data-close-popover-selector]').on('click', function () {
        const tooltipSelector = $(this).data('close-popover-selector');
        $(tooltipSelector).popover('hide');
    });
};

/**
 * Initializing popover for promotions on a page
 */
const initPopoverPromotions = () => {
    queryAll('.promotions-info').forEach((button) => {
        const popoverContent = queryFirst('.promotions-info-popover .promotions-info-popover-content', button.parentElement);

        $(button).popover({
            trigger: 'click',
            placement: 'bottom',
            html: true,
            content: popoverContent
        });
    });
};
/**
 * Initializing popover for strike hearts on a page
 */
const initPopoverWishList = () => {
    queryAll('.strike-wish-list').forEach((button) => {
        const popoverContent = button.dataset.content;
        $(button).popover({
            trigger: 'click',
            placement: 'bottom',
            html: true,
            content: popoverContent
        });
    });
};

/**
 * Early access locked message popover
 */
const initPopoverEarlyAccessLocked = () => {
    const earlyAccessMessage = queryFirst('.loyalty-early-access-lock-container .early-access-container-tooltip');

    if (hasClass(queryFirst(earlyAccessLockedSelector), 'has-early-access-container')) {
        initPopover(earlyAccessLockedSelector, POPOVER_CLICK_BOTTOM, {
            content: earlyAccessMessage
        });
    }
};

/**
 * All of usual popover elements on site
 * TODO: Ideally refactor to something dynamical, when it appear in DOM - initialize it
 */
const initPopoverAll = () => {
    initPopover('.ph-number-info', POPOVER_CLICK_BOTTOM, {
        content: phInfoPopover
    });

    const popoverIcons = queryAll('.loyalty-tooltip-icon');
    popoverIcons.forEach((tooltipIcon) => {
        const popContent = queryFirst('.loyalty-tooltip-popover', tooltipIcon.parentElement);

        initPopover(tooltipIcon, POPOVER_CLICK_BOTTOM, {
            content: popContent,
            placement: 'auto'
        });
    });

    initPopover('.birthday-info', POPOVER_CLICK_BOTTOM, {
        content: birthdayInfoPopover
    });

    initPopover('.fpo-info', POPOVER_CLICK_BOTTOM, {
        content: fpoInfoPopover
    });

    initPopover('.checkout-benefits-info', POPOVER_CLICK_BOTTOM, {
        container: '.new-customers',
        offset: '160 0',
        content: checkoutBenefitsInfoPopover
    });

    initPopover('.cvv-info', POPOVER_CLICK_BOTTOM, {
        content: cvvInfoPopover
    });

    initPopover('.gift-return-order-no', POPOVER_CLICK_BOTTOM, {
        content: giftReturnNumberInfoPopover
    });

    initPopover('.gift-card-number-info', POPOVER_CLICK_BOTTOM, {
        content: giftCardInfoPopover
    });

    initPopover('.delivery-fee-info', POPOVER_CLICK_BOTTOM, {
        content: deliveryFeeInfoPopover
    });

    initPopover('.bolt-logout-button-container', POPOVER_CLICK_BOTTOM, {
        content: boltSignoutMessage
    });

    initPopoverPromotions();
    initPopoverWishList();

    if (hasClass(queryFirst(earlyAccessLockedSelector), 'has-early-access-container')) {
        initPopover(earlyAccessLockedSelector, POPOVER_CLICK_BOTTOM, {
            content: earlyAccessMessage
        });
    }

    initPopover(keepUserSignedInPopover, POPOVER_CLICK_BOTTOM, {
        content: keepUserSignedInContainer
    });

    initPopover(pdpChatPopover, {
        html: true,
        container: pdpChatWrapper,
        placement: 'bottom',
        content: pdpChatContainer
    });

    initPopoverClose();
    initPopoverCloseSelector();
};

module.exports.POPOVER_CLICK_BOTTOM = POPOVER_CLICK_BOTTOM;
// export the initialization function for places where popover may be initialized dynamically
module.exports.initPopoverClose = initPopoverClose;
module.exports.initPopoverCloseSelector = initPopoverCloseSelector;
// export function for single initialization of popover
module.exports.initPopover = initPopover;
module.exports.initPopoverPromotions = initPopoverPromotions;
module.exports.initPopoverEarlyAccessLocked = initPopoverEarlyAccessLocked;
module.exports.initPopoverAll = initPopoverAll;
module.exports.initPopoverWishList = initPopoverWishList;
