'use strict';

const { queryFirst } = require('../domUtil');
const { getNestedValue } = require('../util');
const { selectStyleMessageTmpl, ispuSelectStyleMessageTmpl } = require('../templates').productDetail;
const { EMPTY_STRING } = require('../constants');

/**
 * Function to check if shipping preferences view is enabled or not
 * @returns { boolean } - returns isEnabled flag value
 */
function isShippingPreferencesViewEnabled() {
    return getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.isEnabled') || false;
}

/**
 * Function to toggle select color or size info
 * @param {HTMLElement} productContainer - current product element
 */
function toggleSelectSizeInfo(productContainer) {
    const selectedSizeEl = queryFirst('.size-btn.selected', productContainer);
    const availabilityMessageEl = queryFirst('.product-availability .availability-msg', productContainer);
    if (availabilityMessageEl) {
        if (!selectedSizeEl) {
            availabilityMessageEl.innerHTML = selectStyleMessageTmpl(availabilityMessageEl.dataset.selectStylesMessage);
        }
    }

    if (isShippingPreferencesViewEnabled()) {
        const ispuAvailabilityMessageEl = queryFirst('.ispu-preference-container .select-size-info-msg', productContainer);
        if (ispuAvailabilityMessageEl) {
            const messages = getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.messages') || {};
            const { selectStylesMessage } = messages;
            if (!selectedSizeEl && selectStylesMessage) {
                ispuAvailabilityMessageEl.innerHTML = ispuSelectStyleMessageTmpl(selectStylesMessage);
            }
        }
    }
}
/**
 * Helper method to check if product is on early access or not
 * @param {Object|String} earlyAccess - product | early access date
 * @return {Boolean} true, if early access product or false for not
 */
function isEarlyAccessElement(earlyAccess) {
    let isEarlyAccessItem = false;
    const earlyAccessDate = earlyAccess && earlyAccess.earlyAccessUntilDate || earlyAccess;
    if (earlyAccessDate && earlyAccessDate !== 'false') {
        if (new Date(earlyAccessDate).getTime() > new Date().getTime()) {
            isEarlyAccessItem = true;
        }
    }
    return isEarlyAccessItem;
}

module.exports = {
    toggleSelectSizeInfo: toggleSelectSizeInfo,
    isEarlyAccessElement: isEarlyAccessElement,
    isShippingPreferencesViewEnabled: isShippingPreferencesViewEnabled
};

