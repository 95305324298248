'use strict';

const base = require('./base').default;
const detail = require('./detail').default;
const { updateSelectedSwatchProductName, injectAfterpay, injectFitPredictor, injectLongDescription, updateFullProductLink } = require('../quickView/quickViewHelpers').default;
const focusHelper = require('base/components/focus');
const { getJSON } = require('../util');
const { getLastElementInRow, getStyles, hasClass, queryFirst, remove, scrollTo } = require('../domUtil');
const { quickView: quickViewTemplates } = require('../templates');
const $window = $(window);
const $body = $('body');
const { ajaxFormInvalid, ajaxFormSubmit } = require('../clientSideValidation');
const header = queryFirst('.main-header');
const { handleEarlyAccessLogin } = require('../loyalty/loyaltyDrawer');

/**
 * OOTB function
 * Generates the modal window on the first call.
 * @param {HTMLElement} srcElement - quick view initiator element
 */
function getModalHtmlElement(srcElement) {
    const quickViewModalEl = document.getElementById('quickViewModal');
    remove(quickViewModalEl);
    const quickview = $(quickViewTemplates.quickViewModal);
    quickview.data('srcElement', srcElement);
    $('body').append(quickview);
}

/**
 * OOTB function
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));
    const body = $html.find('.product-quickview');
    return { body };
}

/**
 * Modifies a quickview template to include elements specific to this implementation
 * @param {string} template - The rendered template
 * @returns {string} Modified template
 */
function modifyTemplate(template) {
    const openTagIndex = template.indexOf('>') + 1;

    return template.substr(0, openTagIndex) + template.substr(openTagIndex);
}

/**
 * Returns the additional offset that should be scrolled depending on whether the navigation menu is sticky
 * @returns {number} Main navigation offset
 */
function getNavOffset() {
    return hasClass(header, 'sticky') ? header.offsetHeight : 0;
}

/**
 * Function to attach form validation events
 */
function attachFormEvents() {
    const quickViewFormSelector = '.product-quickview form';
    ajaxFormInvalid(quickViewFormSelector);
    ajaxFormSubmit(quickViewFormSelector);
}

/**
 * OOTB function with changes
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {jQuery.event} event - Event object which trigger the action
 */
function fillModalElement(selectedValueUrl, event) {
    const quickViewModal = $('#quickViewModal');
    const { initPopoverClose, initPopoverWishList } = require('../popover');

    $.spinner().start();

    getJSON(
        selectedValueUrl,
        'GET',
        null,
        data => {
            const { quickviewProductInfo, earlyAccessDate } = data;
            var parsedHtml = parseHtml(data.renderedTemplate);

            if (quickViewModal) {
                quickViewModal.find('.modal-body').html(parsedHtml.body);
                quickViewModal.find('.size-chart').attr('href', data.productUrl);
                quickViewModal.find('.enter-message').text(data.enterDialogMessage);

                updateFullProductLink(data.quickViewFullDetailMsg, data.productUrl, quickViewModal);
                injectAfterpay(quickViewModal);
                injectFitPredictor(quickViewModal);
                initPopoverWishList();
                initPopoverClose();

                if (quickviewProductInfo) {
                    window.quickviewProductInfo = quickviewProductInfo;
                    const swatchEl = quickViewModal.find('.color-attribute .swatch-value');
                    const selectedSwatchEl = quickViewModal.find('.color-attribute .swatch-value.selected');
                    const sizeElements = quickViewModal.find('.size-btn');
                    if (quickviewProductInfo && swatchEl.length && !selectedSwatchEl) {
                        quickViewModal.find('.color-attribute').eq(0).click();
                    }
                    if (selectedSwatchEl && sizeElements.length === 1 && !sizeElements.hasClass('selected')) {
                        sizeElements.click();
                    }
                }

                quickViewModal.modal('show');
                if (earlyAccessDate) {
                    const { isEarlyAccessElement } = require('./helper');
                    const isEarlyAccessItem = isEarlyAccessElement(earlyAccessDate);
                    const quickViewContainer = queryFirst('.product-quickview');
                    base.handleEarlyAccessCta(quickViewContainer, isEarlyAccessItem);
                    const earlyAccessGuestSignIn = queryFirst('.js-early-access-sign-in-cta', quickViewContainer);
                    earlyAccessGuestSignIn.addEventListener('click', e => {
                        e.preventDefault();
                        handleEarlyAccessLogin(earlyAccessGuestSignIn);
                        quickViewModal.modal('hide');
                    });
                }
                requestAnimationFrame(() => {
                    $body.trigger('quickview:visible');
                });

                // Only for Gift card quickview attach form events
                if (data.gcType) {
                    attachFormEvents();

                    if (data.gcType === 'pgc' && queryFirst('.cart-page')) {
                        const productLineItemEl = event.target.closest('.product-info-p-giftcard');
                        // Return if the line item is not pgiftcard
                        if (!productLineItemEl) {
                            return;
                        }

                        const lineItemAmountEl = queryFirst('.gift-card-amount', productLineItemEl);
                        if (lineItemAmountEl) {
                            quickViewModal
                                .find('.gift-card-custom-input-group .form-control')
                                .val(lineItemAmountEl.value)
                                .trigger('keyup');
                            quickViewModal
                                .find('.gift-card-amount')
                                .val(lineItemAmountEl.value);
                        }
                    }
                }
            }

            $.spinner().stop();
        },
        () => $.spinner().stop()
    );
}

/**
 * Replaces the content in a quickview container with the template returned by the specified endpoint
 * @param {JQuery} container - jQuery element to inject HTML into
 * @param {string} url - URL of the endpoint that will return a rendered template
 * @param {JQuery} selectedProductDescriptionElement - jQuery element of selected product SEO long description
 */
function injectQuickView(container, url, selectedProductDescriptionElement) {
    const spinner = $.spinner();
    const stopSpinner = () => { spinner.stop(); };

    const { initPopoverEarlyAccessLocked, initPopoverClose, initPopoverWishList } = require('../popover');
    spinner.start();

    getJSON(
        url,
        'GET',
        null,
        data => {
            const { quickviewProductInfo, earlyAccessDate } = data;
            const parent = container.parent();
            const scrollHeight = parent.prop('scrollHeight');
            const scrollY = $window.scrollTop();
            const filledContainers = $('.quickview-container').not(container);
            let containerTop = container.offset().top;
            const isNewContainerBelow = !!filledContainers.length && !!filledContainers.filter((i, openContainer) => $(openContainer).offset().top < containerTop).length;

            filledContainers.remove();

            if (quickviewProductInfo) {
                window.quickviewProductInfo = quickviewProductInfo;
            }

            requestAnimationFrame(() => {
                containerTop = container.offset().top - getNavOffset();

                // If the target container is below an a container that's already injected,
                // the emptying of the existing container will cause the content to jump.
                // To offset this, we can scroll up by the difference in scrollHeight.
                if (isNewContainerBelow) {
                    scrollTo(scrollY - (scrollHeight - parent.prop('scrollHeight')), 0);
                }

                // Modify the template returned by the endpoint so we can keep the OOTB code and
                // so that the OOTB template can be used in other implementation types (e.g. dialogs)
                container.html(modifyTemplate(data.renderedTemplate));
                const sizeElements = container.find('.size-btn');
                const swatchEl = container.find('.color-attribute .swatch-value');
                const selectedSwatchEl = container.find('.color-attribute .swatch-value.selected');
                if (quickviewProductInfo && swatchEl.length && !selectedSwatchEl) {
                    container.find('.color-attribute').eq(0).click();
                }

                if (selectedSwatchEl && sizeElements.length === 1 && !sizeElements.hasClass('selected')) {
                    sizeElements.click();
                }

                // Only for Gift card quickview attach form events
                if (data.gcType) {
                    attachFormEvents();
                }

                updateFullProductLink(data.quickViewFullDetailMsg, data.productUrl, container);
                injectAfterpay(container);
                injectFitPredictor(container);
                injectLongDescription(selectedProductDescriptionElement, container);
                initPopoverWishList();
                initPopoverClose();

                stopSpinner();
                scrollTo(containerTop);

                requestAnimationFrame(() => {
                    $body.trigger('quickview:visible');
                });

                const wishlistButton = queryFirst('.add-to-wish-list', container[0]);

                if (wishlistButton) {
                    setTimeout(() => {
                        wishlistButton.focus();
                    }, 0);
                }
                if (earlyAccessDate) {
                    const { isEarlyAccessElement } = require('./helper');
                    const isEarlyAccessItem = isEarlyAccessElement(earlyAccessDate);
                    const quickViewContainer = queryFirst('.quickview-container');

                    // attaching popover event to tooltip for AJAX call
                    initPopoverEarlyAccessLocked();
                    initPopoverClose();

                    base.handleEarlyAccessCta(quickViewContainer, isEarlyAccessItem);
                    const earlyAccessGuestSignIn = queryFirst('.js-early-access-sign-in-cta', quickViewContainer);
                    earlyAccessGuestSignIn.addEventListener('click', e => {
                        e.preventDefault();
                        handleEarlyAccessLogin(earlyAccessGuestSignIn);
                    });
                }
            });
        },
        stopSpinner
    );
}

let $quickViewTriggerElement = null;

function backFocusToTrigger() {
    if ($quickViewTriggerElement) {
        $quickViewTriggerElement.focus();
        $quickViewTriggerElement = null;
    }
}

module.exports = {
    showQuickview: () => {
        $body.on('click', '.product-grid .quickview', e => {
            e.preventDefault();

            const { target } = e;
            const $target = $(target);
            const container = $target.closest('.product-tile-container');
            const quickviewURL = $target.closest('.quickview').attr('data-href');
            const lastElementInRow = getLastElementInRow(container[0]);
            const quickviewContainer = $(quickViewTemplates.container).insertAfter(lastElementInRow);
            const seoLongDescriptionElement = $target.parents('.product-tile-container').find('.seo-long-description');

            injectQuickView(quickviewContainer, quickviewURL, seoLongDescriptionElement);

            $target.trigger('quickview:show');
            $quickViewTriggerElement = $target;
        });
        $body.on('click', `
            .recommendations-products .quickview,
            .product-listing .quickview,
            .shop-by-style .quickview,
            .style-component .quickview,
            .shop-the-print-tiles .quickview,
            .cart-page .product-edit .edit,
            .cart-page .bundle-edit .edit`, e => {
            e.preventDefault();
            const $target = $(e.target);
            getModalHtmlElement($target);
            const selectedValueUrl = $target.data('href');
            fillModalElement(selectedValueUrl, e);
            $target.trigger('quickview:show');
            $quickViewTriggerElement = $target;
        });

        $body.on('click', '.quickview-container .close-quickview', e => {
            const container = e.target.closest('.quickview-container');
            if (container) {
                const target = $(container).prev();
                const padding = parseInt(getStyles(container, 'padding-top'), 10);

                remove(container);
                $body.trigger('quickview:close');

                // Scroll back to the target row so the user doesn't lose their position
                scrollTo(target.offset().top - padding - getNavOffset(), 250);
                backFocusToTrigger(); // since it is not BP Modal we need to handle it separately
            } else {
                $('#quickViewModal').modal('hide');
            }
        });

        $body.on('click', '.product-quickview .notify-me-btn', function (e) {
            e.preventDefault();
            $(this).closest('.product-quickview').find('#notifyMe').modal('show');
        });
    },
    focusQuickview: function () {
        $body.on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close-quickview').focus();
        });
        $body.on('hidden.bs.modal', '#quickViewModal', backFocusToTrigger);
    },
    trapQuickviewFocus: function () {
        $body.on('keydown', '.product-quickview', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '.product-quickview',
                firstElementSelector: '.close-quickview',
                lastElementSelector: '.full-pdp-link',
                nextToLastElementSelector: '.add-to-cart'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        $body.on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.product-quickview').spinner().start();
        });
    },
    sizeChart: detail.sizeChart,
    hideDialog: function () {
        $body.on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');

            const isCartPage = queryFirst('.cart-container');
            const addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog') || false;
            if (isCartPage && !addToCartWarningDialog) {
                location.reload();
            }
        });
    },
    beforeUpdateAttribute: function () {
        $body.on('product:beforeAttributeSelect', function () {
            $('.product-quickview').spinner().start();
        });
    },
    updateAttribute: function () {
        $body.on('product:afterAttributeSelect', function (e, response) {
            const quickView = response.container[0];

            if (hasClass(quickView, 'product-quickview')) {
                const { productDetailsUrl } = response.data.product;
                const fullPDPLink = queryFirst('.full-pdp-link', quickView);
                fullPDPLink.setAttribute('href', productDetailsUrl);

                updateSelectedSwatchProductName(response.data.product.variationAttributes, $(quickView));
            }
        });
    },
    updateAddToCart: function () {
        $body.on('product:updateAddToCart', function (e, response) {
            const quickView = queryFirst('.product-quickview');

            if (quickView) {
                const disabled = !response.product.readyToOrder || !response.product.available;

                let buttonToUpdate;
                const addToCartButton = queryFirst('button.add-to-cart', quickView);
                if (addToCartButton) {
                    buttonToUpdate = addToCartButton;
                } else {
                    const updateCartButton = queryFirst('.update-cart-product-global', quickView);
                    if (updateCartButton) {
                        buttonToUpdate = updateCartButton;
                    }
                }

                if (buttonToUpdate) {
                    if (disabled) {
                        buttonToUpdate.setAttribute('disabled', disabled);
                    } else {
                        buttonToUpdate.removeAttribute('disabled');
                    }
                }
            }
        });
    },
    updateAvailability: function () {
        $body.on('product:updateAvailability', function (e, response) {
            const quickView = queryFirst('.product-quickview');

            if (quickView) {
                $('.product-availability', response.$productContainer)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    }
};
