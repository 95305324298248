'use strict';

const { queryFirst } = require('../domUtil');
const { sauce } = require('fitpredictor/product/secretsauce');

/**
 * Updates the product view when a product attribute is selected or deselected or when changing quantity
 * @param {Array} variationAttributes - Array of product variation attributes
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateSelectedSwatchProductName(variationAttributes, $productContainer) {
    if (Array.isArray(variationAttributes) && variationAttributes.length) {
        const colorVariationObject = variationAttributes.find(attribute => attribute.attributeId === 'color');

        if (colorVariationObject && Array.isArray(colorVariationObject.values) && colorVariationObject.values.length) {
            const selectedSwatchObject = colorVariationObject.values.find(eachValue => eachValue.selected);
            $productContainer.find('.selected-swatch-name').text(selectedSwatchObject ? selectedSwatchObject.displayValue : '');
        }
    }
}

/**
 * Function to initialize Fit predictor on quick view
 * @param {jQuery} containerEl - Quickview container element
 */
function injectFitPredictor(containerEl) {
    if (!!document.getElementById('fitPredictorEnabled')) {
        sauce($('.product-detail.product-quickview', containerEl));
    }
}

/**
 * Initialize Afterpay in QuickViews
 */
function injectAfterpay() {
    require('afterpay/afterpay')({
        anchors: '.product-quickview-detail',
        observerTargets: '.prices',
        priceTargets: ['.sales .price-value', '.list .price-value', '.sales .value'],
        renderMode: 'adjacent',
        renderTarget: '.prices',
        showExcluded: false
    });
}

/**
 * Function to sets href for full pdp link
 * @param {string} label - Label to set
 * @param {string} link - href value
 * @param {jQuery} containerEl - Parent DOM element
 */
function updateFullProductLink(label, link, containerEl) {
    containerEl.find('.full-pdp-link').text(label).attr('href', link);
}

/**
 * Function to add long Description on quick view
 * @param {jQuery} description - Product Description getting DOM element
 * @param {jQuery} containerEl - Parent DOM element
 */
function injectLongDescription(description, containerEl) {
    containerEl.find('.long-description').html(description.html());
}

export default {
    updateSelectedSwatchProductName,
    injectAfterpay,
    injectFitPredictor,
    updateFullProductLink,
    injectLongDescription
}
